'use client';

import {
	BigNum,
	BN,
	convertToNumber,
	PRICE_PRECISION_EXP,
	PositionDirection,
	TEN_THOUSAND,
	SpotMarketConfig,
	MarketType,
} from '@drift-labs/sdk';
import Text from 'src/components/Text/Text';
import ValueDisplay from 'src/components/ValueDisplay';
import useAccountData from 'src/hooks/useAccountData';
import useMarkPrice from 'src/hooks/useMarkPrice';
import React, { useEffect, useRef, useState } from 'react';
import { EventEmitter } from 'events';
import { useBooleanLocalStorageState } from 'src/utils/localStorageUtils';
import NumLib from 'src/utils/NumLib';
import { ZERO } from '../../constants/constants';
import useUserAccountIsReady from '../../hooks/useUserAccountIsReady';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { Info } from '@drift-labs/icons';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import UI_UTILS from 'src/utils/uiUtils';
import { useTradeformPriceImpact } from '../../hooks/usePriceImpact';
import useDriftClientIsReady from '../../hooks/useDriftClientIsReady';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { AuctionPriceTooltip, SlippageTooltip } from './PriceTooltips';
import MakerRebateTooltip from '../Tooltip/MakerRebateTooltip';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import { COMMON_UI_UTILS, MarketId } from '@drift/common';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import useTradeFormPreviewOrder from './useTradeFormPreviewOrder';
import Utility from '../Inputs/Utility';

const AdvancedSpotTradeSettings = (props: { orderType: string }) => {
	const markPrice = useMarkPrice();

	const isPostOnly = useDriftStore(
		(s) =>
			s.tradeForm.postOnly &&
			(s.tradeForm.orderType === 'limit' ||
				s.tradeForm.orderType === 'oracleLimit')
	);

	const tradeForm = useDriftStore((s) => s.tradeForm);
	const tradeFormSlippageTolerance = tradeForm.slippageTolerance;

	const { baseSizeStringValue, quoteSizeStringValue } = tradeForm;
	const currentAccount = useAccountData();
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const side = useDriftStore((s) => s.tradeForm.side);
	const driftClientIsReady = useDriftClientIsReady();

	const currentPosition = useAccountTargetSpotBalance(
		selectedMarket.market as SpotMarketConfig,
		currentAccount?.userKey ?? ''
	);

	const userHasMarginEnabled = currentAccount?.marginEnabled ?? false;

	const precision = (selectedMarket.market as SpotMarketConfig).precisionExp;

	const {
		entryPrice,
		priceImpact,
		priceImpactInputBaseSize,
		showPriceEstimateOracleDivergenceWarning:
			priceImpactOracleDivergenceWarning,
	} = useTradeformPriceImpact();

	const marketId = MarketId.createSpotMarket(selectedMarket?.marketIndex);

	const stepSize = useMarketStepSize(marketId);

	const quoteSize = Number(quoteSizeStringValue);
	const baseSizeBigNum = BigNum.fromPrint(baseSizeStringValue, precision);
	const overrideNoValueChange = !baseSizeBigNum || baseSizeBigNum.eqZero();

	const getPriceImpactDisplayValue = () => {
		const priceImpactNum = BigNum.from(
			priceImpact ? priceImpact : 0,
			PRICE_PRECISION_EXP
		).toNum();

		const priceImpactPct = priceImpactNum * 100;

		if (priceImpactPct < 0.01) {
			return `<0.01`;
		} else {
			return priceImpactPct.toFixed(2);
		}
	};

	const getEntryPrice = () =>
		BigNum.from(entryPrice ? entryPrice : 0, PRICE_PRECISION_EXP);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_expectedPrice, setExpectedPrice] = useState(getEntryPrice());
	const [_estimatedPriceImpact, setEstimatedPriceImpact] = useState('0%');
	const [beforeLiqPriceNum, setBeforeLiqPriceNum] = useState(0);
	const [beforeLiqPriceStr, setBeforeLiqPriceStr] = useState('');
	const [afterLiqPriceNum, setAfterLiqPriceNum] = useState(0);
	const [afterLiqPriceStr, setAfterLiqPriceStr] = useState('');

	const previewOrderParams = useTradeFormPreviewOrder();
	const userAccountIsReady = useUserAccountIsReady();
	const quoteSizeNum = Number(quoteSizeStringValue) || 0;

	const showOracleDivergenceWarning = previewOrderParams
		? previewOrderParams.isDivergentFromOracle
		: priceImpactOracleDivergenceWarning;

	const { tradeFee } = useCalculateTradeFee({
		quoteSize: quoteSizeNum,
		marketIndex: selectedMarket?.marketIndex,
		marketType: MarketType.SPOT,
		isPostOnly: isPostOnly,
	});
	const currentLeverage = currentAccount?.marginInfo?.leverage ?? 0;

	useEffect(() => {
		setEstimatedPriceImpact(`${getPriceImpactDisplayValue()}%`);
	}, [priceImpact]);

	useEffect(() => {
		setExpectedPrice(getEntryPrice());
	}, [entryPrice]);

	const previousBaseValue = currentPosition?.netBaseBalance ?? BigNum.zero();

	const afterBaseValue = previousBaseValue.add(
		BigNum.fromPrint(
			baseSizeStringValue ? baseSizeStringValue : '0',
			precision
		).mul(BigNum.from(side === 'buy' ? new BN(1) : new BN(-1)))
	);

	let leverageRatioAfterTrade;
	try {
		leverageRatioAfterTrade =
			!currentAccount ||
			!userAccountIsReady ||
			currentAccount.client?.getTotalCollateral() === ZERO
				? 0
				: convertToNumber(
						currentAccount.client?.accountLeverageRatioAfterTrade(
							selectedMarket.market.marketIndex,
							selectedMarket.marketType,
							NumLib.formatNum.toQuoteBN(quoteSize),
							side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
						) ?? ZERO,
						TEN_THOUSAND
				  );
	} catch (e) {
		console.error(e);
		leverageRatioAfterTrade = 0;
	}

	useEffect(() => {
		if (!driftClientIsReady) return;

		try {
			const beforeLiqPriceNum = UI_UTILS.getSpotLiqPriceNum(
				currentAccount?.client,
				selectedMarket.market.marketIndex
			);
			const beforeLiqPriceStr = UI_UTILS.getSpotLiqPriceStr(
				currentAccount?.client,
				selectedMarket.market.marketIndex
			);

			const afterLiqPriceNum = UI_UTILS.getSpotLiqPriceNum(
				currentAccount?.client,
				selectedMarket.market.marketIndex,
				baseSizeBigNum,
				side === 'buy'
			);
			const afterLiqPriceStr = UI_UTILS.getSpotLiqPriceStr(
				currentAccount?.client,
				selectedMarket.market.marketIndex,
				baseSizeBigNum,
				side === 'buy'
			);

			setBeforeLiqPriceNum(beforeLiqPriceNum);
			setBeforeLiqPriceStr(beforeLiqPriceStr);
			setAfterLiqPriceNum(afterLiqPriceNum);
			setAfterLiqPriceStr(afterLiqPriceStr);
		} catch (err) {
			// swallow error, will throw for 0 base size
			//console.error(err);
		}
	}, [selectedMarket, markPrice, baseSizeStringValue, driftClientIsReady]);

	const [hasShownJitTooltip, setHasShownJitTooltip] =
		useBooleanLocalStorageState('HasShownJitExplanationTooltip', false);

	const jitTooltipEmitter = useRef(new EventEmitter());

	// Show the JIT tooltip if the user has interacted with the trade form and they haven't seen it before
	useEffect(() => {
		if (priceImpact && priceImpact.gt(ZERO) && !hasShownJitTooltip) {
			jitTooltipEmitter.current.emit('event');
			setHasShownJitTooltip(true);
		}
	}, [hasShownJitTooltip, priceImpact?.toString()]);

	// check if price impact is up to date, allow 1% leeway for small fluctuations
	const priceImpactIsCurrent = baseSizeBigNum
		.sub(BigNum.from(priceImpactInputBaseSize, precision))
		.abs()
		.lte(baseSizeBigNum.scale(1, 100));

	const isMobile = useIsMobileScreenSize();

	const auctionStartElement = (
		<ValueDisplay.Default
			warn={showOracleDivergenceWarning}
			label={
				<span>
					{side === 'buy' ? 'Min' : 'Max'} Price
					<Info size={14} className="relative top-[3px] left-1" />
				</span>
			}
			value={
				!overrideNoValueChange && previewOrderParams && priceImpactIsCurrent ? (
					<>
						{COMMON_UI_UTILS.trimTrailingZeros(
							UI_UTILS.toNotional(
								BigNum.from(
									previewOrderParams?.auctionStartPrice ?? ZERO,
									PRICE_PRECISION_EXP
								).toNum(),
								5
							),
							2
						)}
					</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[2px]'}
		/>
	);

	const auctionEndElement = (
		<ValueDisplay.Default
			warn={showOracleDivergenceWarning}
			label={
				<div className="flex items-center">
					<span>
						{side === 'buy' ? 'Max' : 'Min'} Price
						<Info size={14} className="relative top-[3px] left-1" />
					</span>
					{previewOrderParams?.constrainedBySlippage && baseSizeStringValue && (
						<div
							className={`text-warn-yellow ml-2 px-1 py-0.5 space-x-2 border rounded text-xs`}
							style={{
								borderColor: 'var(--yellow-80)',
							}}
						>
							Low Slippage
						</div>
					)}
				</div>
			}
			value={
				!overrideNoValueChange && previewOrderParams && priceImpactIsCurrent ? (
					<>
						{COMMON_UI_UTILS.trimTrailingZeros(
							UI_UTILS.toNotional(
								BigNum.from(
									previewOrderParams?.auctionEndPrice ?? ZERO,
									PRICE_PRECISION_EXP
								).toNum(),
								5
							),
							2
						)}
					</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[2px]'}
		/>
	);

	const dynamicSlippageElement = (
		<ValueDisplay.Default
			label={
				<span>
					Dynamic Slippage
					<Info size={14} className="relative top-[3px] left-1" />
				</span>
			}
			value={
				!overrideNoValueChange &&
				previewOrderParams?.slippage &&
				priceImpactIsCurrent &&
				tradeFormSlippageTolerance === 'dynamic' ? (
					<>{parseFloat(previewOrderParams?.slippage?.toFixed(2))}%</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[3px]'}
		/>
	);

	return (
		<>
			<div className="flex flex-col w-full space-y-2 rounded-md advanced-trade-details">
				{props.orderType === 'market' ? (
					<>
						<Utility.VERTSPACERXS />
						<Utility.VERTDIVIDER />
						<Utility.VERTSPACERXS />

						{tradeFormSlippageTolerance === 'dynamic' ? (
							<>
								{isMobile ? (
									dynamicSlippageElement
								) : (
									<SlippageTooltip
										childElement={dynamicSlippageElement}
										placement="right"
										showHighSlippageWarning={previewOrderParams?.slippage >= 2}
									/>
								)}
							</>
						) : (
							<>
								{isMobile ? (
									<>{auctionStartElement}</>
								) : (
									<AuctionPriceTooltip
										placement="right"
										type="start"
										price={previewOrderParams?.auctionStartPrice ?? ZERO}
										isOracleOrder={previewOrderParams?.isOracleOrder}
										showConstrainedBySlippageWarning={false}
										childElement={auctionStartElement}
									/>
								)}

								{isMobile ? (
									<>{auctionEndElement}</>
								) : (
									<AuctionPriceTooltip
										placement="right"
										type="end"
										price={previewOrderParams?.auctionEndPrice ?? ZERO}
										isOracleOrder={previewOrderParams?.isOracleOrder}
										showConstrainedBySlippageWarning={
											previewOrderParams?.constrainedBySlippage
										}
										childElement={auctionEndElement}
									/>
								)}
							</>
						)}

						<Utility.VERTSPACERXS />
						<Utility.VERTDIVIDER />
						<Utility.VERTSPACERXS />

						{/* <ValueDisplay.Default
							label="Est. Price Impact"
							value={
								priceImpact !== undefined &&
								!overrideNoValueChange &&
								priceImpactIsCurrent ? (
									priceImpact.gt(ZERO) ? (
										<>{estimatedPriceImpact}</>
									) : (
										<>{'0%'}</>
									)
								) : (
									<SkeletonValuePlaceholder
										className="h-[14px] w-[50px]"
										loading={!overrideNoValueChange}
									/>
								)
							}
						/> */}

						<ValueDisplay.ValueChange
							label="Est. Liquidation Price"
							previousValue={beforeLiqPriceNum}
							afterValue={afterLiqPriceNum}
							previousValuePrint={beforeLiqPriceStr}
							afterValuePrint={afterLiqPriceStr}
							forceWhite
							nullOverride={
								(!beforeLiqPriceNum && !afterLiqPriceNum) ||
								!userAccountIsReady ||
								isNaN(selectedMarket.market.marketIndex)
							}
							customOverride={
								<SkeletonValuePlaceholder
									className="h-[14px] w-[50px]"
									loading={false}
								/>
							}
							overrideNoValueChange={overrideNoValueChange}
						/>

						{userHasMarginEnabled && (
							<ValueDisplay.ValueChange
								label="Acct. Leverage"
								rightSymbol="x"
								previousValue={currentLeverage}
								afterValue={leverageRatioAfterTrade}
								previousValuePrint={currentLeverage.toLocaleString('en-US', {
									maximumSignificantDigits: 3,
								})}
								afterValuePrint={leverageRatioAfterTrade.toLocaleString(
									'en-US',
									{
										maximumSignificantDigits: 3,
									}
								)}
								forceWhite
								nullOverride={
									(!userAccountIsReady ||
										(!currentLeverage && !leverageRatioAfterTrade) ||
										isNaN(selectedMarket.market.marketIndex)) &&
									true
								}
								customOverride={
									<SkeletonValuePlaceholder
										className="h-[14px] w-[50px]"
										loading={false}
									/>
								}
								overrideNoValueChange={overrideNoValueChange}
							/>
						)}
					</>
				) : (
					<></>
				)}

				{isPostOnly && (
					<Text.BODY3 className="flex items-end justify-between w-full">
						<div
							className="flex items-center text-text-secondary"
							title="Entry price slippage from mark price."
						>
							Maker Rebate <MakerRebateTooltip />
						</div>

						<div className="font-numeral text-text-default">
							~
							<span className="text-positive-green">
								${parseFloat(tradeFee.toFixed(4))}
							</span>
						</div>
					</Text.BODY3>
				)}

				{!isPostOnly &&
					(props.orderType === 'market' ||
						props.orderType === 'limit' ||
						props.orderType === 'oracleLimit') && (
						<ValueDisplay.Default
							label={<Text.BODY3>Fees</Text.BODY3>}
							value={
								<div>
									<Text.BODY3>
										{props.orderType === 'oracleLimit' ? '~' : ''}$
										{tradeFee.toFixed(2)}
									</Text.BODY3>
								</div>
							}
						/>
					)}

				<ValueDisplay.ValueChange
					label="Position"
					previousValue={previousBaseValue}
					afterValue={afterBaseValue}
					previousValuePrint={`${previousBaseValue
						.toNum()
						.toLocaleString('en-US', {
							maximumSignificantDigits: 5,
						})} ${selectedMarket.baseAssetSymbol()}`}
					afterValuePrint={`${UI_UTILS.roundToStepSizeIfLargeEnough(
						afterBaseValue.toNum().toLocaleString('en-US', {
							maximumSignificantDigits: 5,
						}),
						stepSize
					)} ${selectedMarket.baseAssetSymbol()}`}
					forceWhite
				/>
			</div>
		</>
	);
};

export default React.memo(AdvancedSpotTradeSettings);
