'use client';

// usePrePreparedPerpTradeFormOrder.ts
import { useEffect, useMemo, useRef, useState } from 'react';
import {
	ORDER_PREP_UTILS,
	PerpTradeFormOutputProps,
} from '../../stores/DriftStore/actions/actionHelpers/orderPrep';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useDriftAccountStore from '../../stores/useDriftAccountsStore';
import useDriftActions from '../useDriftActions';
import {
	TransactionPrepHandler,
	PreppedMarketOrders,
} from '../../utils/TransactionPrep/TransactionPrepHandler';
import { dlog } from '../../dev';
import { TRANSACTION_PREP_CONFIG } from '../../utils/TransactionPrep/TransactionPrepConfig';
import Env from '../../environmentVariables/EnvironmentVariables';
import { Transaction, VersionedTransaction } from '@solana/web3.js';
import { MarketId } from '@drift/common';
import useMarketStateStore from 'src/stores/useMarketStateStore';

type TradeBackgroundParams = Pick<
	PerpTradeFormOutputProps,
	'priceImpact' | 'oraclePrice'
>;

type TradeForegroundParams = Omit<
	PerpTradeFormOutputProps,
	'priceImpact' | 'oraclePrice'
>;

const usePrePreparedPerpTradeFormOrder = (
	params: PerpTradeFormOutputProps
): null | {
	placeAndTakeTx: Transaction | VersionedTransaction;
	cancelExistingOrdersTx: Transaction | VersionedTransaction;
	settlePnlTx: Transaction | VersionedTransaction;
} => {
	if (
		!TRANSACTION_PREP_CONFIG.prePreppedTxsEnabled ||
		!Env.useCachedBlockhashFetcherInDriftClient // This is a bit of a weird one, but without this enabled then we may be fetching the blockhash every time we prepare a transaction. This is fine as long as the debounce window is fine but theoretically they should be decoupled. As long as we're using the cached fetcher then things should be fine however. This is the easiest way to ensure this for now, unless we can figure out a more implicit way to ensure that the blockhash is cached.
	)
		return null; // NOTE 🚨 : Returning null is important at the moment. The pre_prepped_transaction metric logic uses this to implicitly determine whether it was a "liable but empty pre-prepped tx" order vs an order where we don't expect a pre-prepped tx to be attached at all .. (it should be undefined in that case).

	const [preparedTrade, setPreparedTrade] =
		useState<PreppedMarketOrders | null>(null);
	const transactionPrepRef = useRef<TransactionPrepHandler | null>(null);
	const isSwiftSelected = useDriftStore((s) => s.tradeForm.isSwiftSelected);

	const getDriftStore = useDriftStore((s) => s.get);
	const getAccountsStore = useDriftAccountStore((s) => s.get);
	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);

	const getPriceInfo = useMemo(
		() => (marketId: MarketId) => getMarketDataForMarket(marketId).derivedState,
		[getMarketDataForMarket]
	);

	const actions = useDriftActions();

	const updatePreparedTrade = (preppedTrade: PreppedMarketOrders) => {
		setPreparedTrade(preppedTrade);
	};

	useEffect(() => {
		if (!actions || !getDriftStore || !getAccountsStore) return;

		const tradePrepHarness = {
			getUserAccountData: () => {
				const accountsState = getAccountsStore();
				return accountsState.accounts[accountsState.currentUserKey];
			},
			getDriftClient: () => getDriftStore().driftClient.client,
			getPriceInfo,
		};

		transactionPrepRef.current = new TransactionPrepHandler({
			debounceTimeMs: TRANSACTION_PREP_CONFIG.debounceTimeMs,
			expiryEvents: TRANSACTION_PREP_CONFIG.expiryEvents,
			processingFunction: async (input) => {
				dlog(
					'pre-prepped_transactions',
					`running_pre-prep_processing_function`
				);

				if (isSwiftSelected) {
					dlog(
						'pre-prepped_transactions',
						`skipping_pre-prep_processing_function_for_swift`
					);
					return;
				}

				const preppedParams = await ORDER_PREP_UTILS.prepPerpMarketOrderParams(
					input,
					tradePrepHarness
				);
				return actions.prepPerpPlaceAndTake(preppedParams);
			},
		});

		const subscription = transactionPrepRef.current
			.getOutput()
			.subscribe(updatePreparedTrade);

		return () => subscription.unsubscribe();
	}, [actions, getDriftStore, getAccountsStore, isSwiftSelected]);

	// We're splitting the params into background and foreground. The rationale is that background params should be able to change without the trade necesarily needing to be pre-prepared (TODO - need to confirm this). But foreground ones should definitely invalidate whatever was cached.
	const backgroundParams: TradeBackgroundParams = useMemo(() => {
		return {
			oraclePrice: params.oraclePrice,
			priceImpact: params.priceImpact,
		};
	}, [params.oraclePrice, params.priceImpact]);

	const foregroundParams: TradeForegroundParams = useMemo(
		() => params,
		[
			params.allowInfSlippage,
			params.baseSizeStringValue,
			params.bracketOrders,
			params.immediateOrCancel,
			params.leadSide,
			params.maxLeverageSelected,
			params.orderType,
			params.postOnly,
			params.reduceOnly,
			params.secondaryPriceBoxStringValue,
			params.side,
			params.targetMarketIndex,
			params.perpMarketAccount,
		]
	);

	useEffect(() => {
		if (transactionPrepRef.current) {
			transactionPrepRef.current.updateInput('foreground', params);
		}
	}, [foregroundParams]);

	useEffect(() => {
		if (transactionPrepRef.current) {
			transactionPrepRef.current.updateInput('background', params);
		}
	}, [backgroundParams]);

	return preparedTrade;
};

export default usePrePreparedPerpTradeFormOrder;
