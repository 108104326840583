'use client';

import { PublicKey } from '@solana/web3.js';
import { useEffect, useState } from 'react';
import useAccountData from './useAccountData';
import useUserAccountIsReady from './useUserAccountIsReady';

const useUserAccountPubKey = (): PublicKey | null => {
	const [pubkey, setPubkey] = useState<PublicKey | null>(null);
	const userAccountIsReady = useUserAccountIsReady();

	const userAccount = useAccountData()?.client ?? null;

	useEffect(() => {
		if (userAccountIsReady) {
			setPubkey(userAccount?.getUserAccountPublicKey());
		} else {
			setPubkey(null);
		}
	}, [userAccountIsReady, userAccount]);

	return pubkey;
};

export default useUserAccountPubKey;
