'use client';

import { BigNum, PositionDirection, ZERO } from '@drift-labs/sdk';
import { COMMON_UI_UTILS, ENUM_UTILS, MarketId } from '@drift/common';
import React from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { compareDriftProps } from 'src/utils/compareProps';
import Text from 'src/components/Text/Text';
import useGetSlippageForMarket from './useGetSlippageForMarket';

type EstimatedProfitProps = {
	marketId: MarketId;
	currentPositionSize: BigNum;
	currentPositionEntryPrice: BigNum;
	currentPositionDirection: 'long' | 'short';
	side: 'buy' | 'sell';
	limitExitSize: BigNum;
	limitExitPrice: BigNum;
	takerFeeBps?: number;
	customLabel?: string;
	showPlusSign?: boolean;
	isMarketOrder?: boolean;
};

const EstimatedProfit = (props: EstimatedProfitProps) => {
	if (!props.currentPositionSize) return null;

	const slippageTolerance = useDriftStore((s) => s.tradeForm.slippageTolerance);
	const getSlippageForMarket = useGetSlippageForMarket();

	const { estimatedProfit } = COMMON_UI_UTILS.calculatePotentialProfit({
		currentPositionSize: props.currentPositionSize,
		currentPositionDirection: ENUM_UTILS.toObj(props.currentPositionDirection),
		tradeDirection:
			props.side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT,
		exitBaseSize: props.limitExitSize,
		exitPrice: props.limitExitPrice,
		currentPositionEntryPrice: props.currentPositionEntryPrice,
		slippageTolerance: getSlippageForMarket(
			props.marketId,
			slippageTolerance,
			props.side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
		), // NOTE - right now this component only uses the current market id, we will have to pass the priceImpactInfo if using for a different market
		isMarketOrder: props.isMarketOrder,
		takerFeeBps: props.takerFeeBps || 0,
	});

	return (
		<div>
			{estimatedProfit ? (
				<Text.BODY3 className="flex items-center justify-between w-full space-x-2">
					<div className="text-text-secondary">
						{props.customLabel ?? 'Est. Realized P&L'}
					</div>
					<div
						className={
							estimatedProfit.gte(ZERO)
								? 'text-positive-green'
								: 'text-negative-red'
						}
					>
						{estimatedProfit.gte(ZERO) ? (props.showPlusSign ? '+' : '') : '-'}
						{estimatedProfit.abs().toNotional()}
					</div>
				</Text.BODY3>
			) : (
				<></>
			)}
		</div>
	);
};

export default React.memo(EstimatedProfit, compareDriftProps);
