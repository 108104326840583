'use client';

import React, { useCallback, useState } from 'react';
import TextField from 'src/components/Inputs/TextField';
import Text from 'src/components/Text/Text';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import MarketIcon from '../Utils/MarketIcon';
import UI_UTILS from 'src/utils/uiUtils';
import { Info, Swap } from '@drift-labs/icons';
import SlippageToleranceDisplay from './SlippageToleranceDisplay';
import Tooltip from '../Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import UnderlinedTabs from '../TabbedNavigation/UnderlinedTabs';
import { UIOrderType } from '@drift/common';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import useGetTickSizeForMarket, {
	getTickSizeDecimalPlaces,
} from 'src/hooks/useGetTickSizeForMarket';

export const PredictionMarketYesAndNoPriceDisplay = React.memo(
	function PredictionMarketYesAndNoPriceDisplay() {
		const currentlySelectedMarketConfig = useDriftStore(
			(s) => s.selectedMarket.current
		);

		const marketId = currentlySelectedMarketConfig.marketId;

		const marketData = useMarketStateStore((s) =>
			s.getMarketDataForMarket(marketId)
		);
		const getTickSizeForMarket = useGetTickSizeForMarket();
		const tickSize = getTickSizeForMarket(marketId);
		const decimalPlaces = getTickSizeDecimalPlaces(tickSize);

		const bestBid = marketData?.derivedState?.bestBid;
		const bestAsk = marketData?.derivedState?.bestAsk;

		const yesPrice = bestAsk?.toNum() ?? 0;
		const noPrice = 1 - (bestBid?.toNum() ?? 1);

		const yesPriceString = UI_UTILS.toFixedLocaleString(
			yesPrice,
			decimalPlaces
		);
		const noPriceString = UI_UTILS.toFixedLocaleString(noPrice, decimalPlaces);

		const renderPrice = (priceString: string, side: 'yes' | 'no') => {
			return (
				<div
					className={twMerge(
						'mt-4 text-text-label flex flex-col w-1/2 gap-1',
						side === 'yes' ? 'pr-2' : 'pl-2 items-end'
					)}
				>
					<div className="flex items-center space-x-1">
						<Text.BODY3>{side === 'yes' ? 'Yes' : 'No'} Price</Text.BODY3>
						<Tooltip
							content={
								<>
									<Text.BODY3>
										<span>
											{side === 'yes'
												? 'The Yes price is based on the best available ask price on the orderbook.'
												: 'The No price is based on the best available bid price on the orderbook.'}{' '}
											Due to spread, the prices may not add up to exactly $1.
										</span>
									</Text.BODY3>
								</>
							}
							size={14}
						/>
					</div>
					<Text.BODY3 className="text-text-default">${priceString}</Text.BODY3>
				</div>
			);
		};

		return (
			<div className="flex w-full">
				{renderPrice(yesPriceString, 'yes')}
				{renderPrice(noPriceString, 'no')}
			</div>
		);
	}
);

const SwapInputSideButton = (props: {
	side: 'base' | 'quote';
	baseAssetSymbol: string;
	marketSymbol: string;
	onClick: () => void;
}) => {
	const [hovered, setHovered] = React.useState(false);

	const handleChangeSide = () => {
		props.onClick();
	};

	return (
		<button
			className="flex flex-row items-center px-2 py-1 space-x-2 transition-all border rounded-md text-text-default bg-button-secondary-bg hover:bg-button-secondary-bg-hover border-button-border"
			onClick={handleChangeSide}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			data-testid={`swap-input-side-button`}
		>
			<MarketIcon
				marketSymbol={props.side === 'base' ? props.marketSymbol : 'USDC'}
			/>{' '}
			<Text.BODY2>
				{props.side === 'base' ? props.baseAssetSymbol : 'USDC'}
			</Text.BODY2>{' '}
			<span
				className={`transition-all ${
					hovered ? 'text-text-default' : 'text-text-label'
				} relative top-1`}
			>
				<Swap size={20} />
			</span>
		</button>
	);
};

export const SizeSelectorLite = React.memo(function SizeSelectorMemo(props: {
	baseStepSize: number;
	quoteStepSize: number;
	baseSizeStringValue: string;
	quoteSizeStringValue: string;
	maxQuote: number;
	maxBase: number;
	handleChangeSizeBase: (value: string) => void;
	handleChangeSizeQuote: (value: string) => void;
	handleChangeMaxLeverageSelected: (value: boolean) => void;
	quoteOnly?: boolean;
	className?: string;
	defaultSide?: 'base' | 'quote';
	isInDepositAndTradeState?: boolean;
	baseSizeInputId?: string;
}) {
	// inputSide just controls the larger/editable display value for the lite input field
	// And is completely independent of "leadSide" in the trade form state engine
	const [inputSide, setInputSide] = useState<'base' | 'quote'>(
		props?.quoteOnly ? 'quote' : props.defaultSide ? props.defaultSide : 'base'
	);
	const marketSymbol = useDriftStore((s) => s.selectedMarket.current.symbol);
	const baseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol(true)
	);
	const isPredictionMarket = useDriftStore(
		(s) => s.selectedMarket.current.isPredictionMarket
	);

	// Base size rounded to step size
	const roundedBaseSize = UI_UTILS.roundToStepSizeIfLargeEnough(
		props.baseSizeStringValue,
		props.baseStepSize
	);

	const roundedMaxBase = parseFloat(
		UI_UTILS.roundToStepSizeIfLargeEnough(
			`${props.maxBase}`,
			props.baseStepSize
		)
	);

	const maxString =
		inputSide === 'base'
			? `${roundedMaxBase}`
			: UI_UTILS.toFixedLocaleString(props.maxQuote, 2);

	const baseSizeChangeIsMaxLeverage = useCallback(
		(newBaseSize: string) => {
			if (props.isInDepositAndTradeState) return false;

			// if a user is THIS close to max lev, give them max lev
			return Number.parseFloat(newBaseSize) >= props.maxBase * 0.995;
		},
		[props.maxBase, props.isInDepositAndTradeState]
	);

	const quoteSizeChangeIsMaxLeverage = useCallback(
		(newQuoteSize: string) => {
			if (props.isInDepositAndTradeState) return false;

			// if a user is THIS close to max lev, give them max lev
			return Number.parseFloat(newQuoteSize) >= props.maxQuote * 0.995;
		},
		[props.maxQuote, props.isInDepositAndTradeState]
	);

	const roundForMaxBase = (newBaseSize: string) => {
		if (+newBaseSize === 0) return newBaseSize;
		if (props.isInDepositAndTradeState) return newBaseSize;

		return Math.min(parseFloat(newBaseSize), props.maxBase).toString();
	};

	const roundForMaxQuote = (newBaseSize: string) => {
		if (props.isInDepositAndTradeState) return newBaseSize;

		const newBaseSizeNum = parseFloat(newBaseSize);
		if (newBaseSizeNum > props.maxQuote) {
			return props.maxQuote.toFixed(2);
		}

		return newBaseSize;
	};

	const roundForStepSize = (newBaseSize: string) => {
		if (+newBaseSize === 0) return newBaseSize;
		return UI_UTILS.roundToStepSizeIfLargeEnough(
			newBaseSize,
			props.baseStepSize
		);
	};

	const handleInputChange = (newVal: string) => {
		if (inputSide === 'base') {
			if (isNaN(+newVal) || newVal === '') {
				props.handleChangeSizeBase('');
				props.handleChangeMaxLeverageSelected(false);
				return;
			}
			const roundedVal = roundForStepSize(roundForMaxBase(newVal));
			props.handleChangeSizeBase(roundedVal);
			props.handleChangeMaxLeverageSelected(
				baseSizeChangeIsMaxLeverage(roundedVal)
			);
		} else {
			if (!newVal) {
				props.handleChangeSizeQuote('');
				props.handleChangeMaxLeverageSelected(false);
				return;
			}
			const roundedVal = roundForMaxQuote(newVal);
			props.handleChangeSizeQuote(roundedVal);
			props.handleChangeMaxLeverageSelected(
				quoteSizeChangeIsMaxLeverage(roundedVal)
			);
		}
	};

	const handleMax = () => {
		props.handleChangeMaxLeverageSelected(true);

		if (inputSide === 'base') {
			props.handleChangeSizeBase(props.maxBase?.toString() ?? '0');
		} else {
			props.handleChangeSizeQuote(props.maxQuote?.toString() ?? '0');
		}
	};

	return (
		<div className={twMerge('relative my-5', props.className)}>
			<div className="flex flex-row items-center justify-between w-full py-0.5 text-text-label">
				<Text.BODY1>Trade Size</Text.BODY1>
				{!props.isInDepositAndTradeState && (
					<button
						className="px-1 leading-4 text-text-label bg-button-secondary-bg"
						onClick={handleMax}
					>
						<Text.MICRO1>
							Max: {maxString}{' '}
							{inputSide === 'quote'
								? 'USDC'
								: isPredictionMarket
								? 'Shares'
								: baseAssetSymbol}
						</Text.MICRO1>
					</button>
				)}
			</div>
			<div className="mt-2">
				<TextField.Hero
					inputFieldValueType={inputSide === 'base' ? 'base' : 'quote'}
					subtextFieldValueType={inputSide === 'base' ? 'quote' : 'base'}
					stepSize={
						inputSide === 'base' ? props.baseStepSize : props.quoteStepSize
					}
					depsForOnChange={[
						inputSide,
						props.baseStepSize,
						props.quoteStepSize,
						props.maxBase,
						props.maxQuote,
						props.isInDepositAndTradeState,
					]}
					data-testid="perp-trade-size"
					type="number"
					onChange={handleInputChange}
					value={
						inputSide === 'base' ? roundedBaseSize : props.quoteSizeStringValue
					}
					placeholder="0"
					subtextValue={
						inputSide === 'base'
							? `${`$${props.quoteSizeStringValue}` || '$0.00'}`
							: `${`${roundedBaseSize}` || '0'}`
					}
					subtextLabel={
						inputSide === 'base'
							? // if the inputSide is base then the subtext is quote and no label is needed
							  ''
							: // if the inputSide is quote then the subtext is base and the label should be the asset symbol (or "Shares")
							isPredictionMarket
							? 'Shares'
							: baseAssetSymbol
					}
					suffixNode={
						props.quoteOnly ? (
							<Text.BODY1>USDC</Text.BODY1>
						) : (
							<SwapInputSideButton
								side={inputSide}
								baseAssetSymbol={
									isPredictionMarket ? 'Shares' : baseAssetSymbol
								}
								marketSymbol={marketSymbol}
								onClick={() =>
									setInputSide(inputSide === 'base' ? 'quote' : 'base')
								}
							/>
						)
					}
					id={props.baseSizeInputId}
				/>
			</div>
		</div>
	);
});

const SHOW_COST_PER_SHARE_CALCULATION = false;

export const MarkPriceLite = React.memo(function MarkPriceMemo(props: {
	value: number;
	decimals?: number;
	loading?: boolean;
	warn?: boolean;
	hideSlippageTolerance?: boolean;
	requireSwitchingToProModeToSeeOrderbook?: boolean;
	className?: string;
}) {
	const isMobile = useIsMobileScreenSize();
	const uiMarket = useDriftStore((s) => s.selectedMarket.current);

	const yesPrice = props.value;
	const noPrice = 1 - props.value;

	const yesPriceString = UI_UTILS.toFixedLocaleString(
		yesPrice,
		props.decimals ?? 4
	);
	const noPriceString = UI_UTILS.toFixedLocaleString(
		noPrice,
		props.decimals ?? 4
	);

	const priceToUseString = yesPriceString;

	return (
		<div className={twMerge('my-5', props.className)}>
			<div className="pb-2">
				<Tooltip
					allowHover
					placement={isMobile ? 'top' : 'right'}
					content={
						<div className="text-text-default min-w-[350px]">
							<Text.BODY2>
								{uiMarket.isPredictionMarket ? (
									<>
										Cost per share is based on the available bid/ask liquidity
										on the orderbook.
										{SHOW_COST_PER_SHARE_CALCULATION && (
											<>
												<br />
												<br />
												<div className="flex items-center justify-between">
													<span>Cost of 1 x &quot;No&quot; share</span>
													<span>=</span>
													<span>
														${MAX_PREDICTION_PRICE_NUM} - Cost of 1 x
														&quot;Yes&quot; share
													</span>
												</div>
												{props.value !== 0 && (
													<>
														<br />
														<div className="flex justify-between">
															<span>Cost of &quot;Yes&quot; Share</span>
															<span>${yesPriceString}</span>
														</div>
														<div className="flex justify-between mt-1">
															<span>Cost of &quot;No&quot; Share</span>
															<span>
																${MAX_PREDICTION_PRICE_NUM} - ${yesPriceString}{' '}
																= ${noPriceString}
															</span>
														</div>
													</>
												)}
											</>
										)}
									</>
								) : (
									<>
										Mark price is the mid point of the best bid/ask on the
										orderbook.
										<br /> <br />
										You can see detailed bid/ask liquidity in the orderbook
										{props.requireSwitchingToProModeToSeeOrderbook
											? ' (requires switching to pro mode)'
											: ''}
										.
									</>
								)}
							</Text.BODY2>
						</div>
					}
					className="w-[150px]"
				>
					<Text.BODY2
						className={props.warn ? 'text-warn-yellow' : 'text-text-label'}
					>
						{uiMarket.isPredictionMarket ? 'Cost per share' : 'Mark Price'}
					</Text.BODY2>
					<Info
						className="ml-1"
						color={props.warn ? 'var(--warn-yellow)' : 'var(--text-label)'}
					/>
				</Tooltip>
			</div>
			<div className="flex flex-row items-center justify-between w-full p-3 bg-container-bg-selected">
				<div>
					<Text.H2
						className={twMerge(
							props.value > 0 ? 'text-text-default' : 'invisible',
							'cursor-default'
						)}
					>
						${priceToUseString}
					</Text.H2>
				</div>
			</div>
			{props?.hideSlippageTolerance ? null : (
				<div className="mt-2">
					<SlippageToleranceDisplay />
				</div>
			)}
		</div>
	);
});

export const LeverageLabelLite = React.memo(function LeverageLabelMemo() {
	const isMobile = useIsMobileScreenSize();

	return (
		<Tooltip
			allowHover
			placement={isMobile ? 'top' : 'right'}
			content={
				<div className="text-text-default">
					<Text.BODY2>
						Leverage available depends on the weighted value of your
						account&apos;s assets and liabilities in Drift&apos;s margin system,
						and the max leverage of the selected market.
					</Text.BODY2>
				</div>
			}
			className="w-[90px]"
		>
			<Text.BODY2 className="text-text-label">Leverage</Text.BODY2>
			<Info className="ml-1" color="var(--text-label)" />
		</Tooltip>
	);
});

export const MarketLimitToggle = React.memo(
	function MarketLimitToggleMemo(props: {
		orderType: UIOrderType;
		onChange: (orderType: UIOrderType) => void;
	}) {
		const options = [
			{
				value: 'market',
				label: 'Market',
			},
			{
				value: 'limit',
				label: 'Limit',
			},
		];

		return (
			<div className="w-full mb-4">
				<UnderlinedTabs
					currentSelection={props.orderType ?? 'market'}
					options={options}
					onChange={(newVal) => props.onChange(newVal as UIOrderType)}
					fadeOutBorder={false}
				/>
			</div>
		);
	}
);

export const LimitPriceBoxLite = React.memo(function EstEntryPriceMemo(props: {
	value: string;
	onChange: (value: string) => void;
	tickSize: number;
	warn?: boolean;
	className?: string;
}) {
	// const isMobile = useIsMobileScreenSize();

	return (
		<div className={twMerge('my-5', props.className)}>
			{/* <Tooltip
					allowHover
					placement={isMobile ? 'top' : 'right'}
					content={
						<div className="text-text-default">
							<Text.BODY2>
								Price at which the order may be filled
							</Text.BODY2>
						</div>
					}
					className="w-[150px]"
				> */}
			<Text.BODY2
				className={props.warn ? 'text-warn-yellow' : 'text-text-label'}
			>
				Limit Price
			</Text.BODY2>
			{/* <Info
				className="ml-1"
				color={props.warn ? 'var(--warn-yellow)' : 'var(--text-label)'}
			/> */}
			{/* </Tooltip> */}

			<div className="pt-1 pb-5">
				<TextField.Default
					value={props.value}
					type="number"
					stepAmount={props.tickSize}
					onChange={props.onChange}
					prefix={'$'}
					larger
				/>
			</div>
		</div>
	);
});
