'use client';

import { useEffect, useState } from 'react';
import { DEFAULT_MARKET_AUCTION_DURATION } from 'src/environmentVariables/DefaultSettings';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { COMMON_UI_UTILS, ENUM_UTILS } from '@drift/common';
import {
	PositionDirection,
	MAX_LEVERAGE_ORDER_SIZE,
	BigNum,
	BASE_PRECISION_EXP,
	BN,
	OrderType,
	PRICE_PRECISION_EXP,
	PerpMarketAccount,
} from '@drift-labs/sdk';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import useMarkPrice from 'src/hooks/useMarkPrice';
import { useTradeformPriceImpact } from 'src/hooks/usePriceImpact';
import { singletonHook } from 'react-singleton-hook';
import Env, {
	syncGetCurrentSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import UI_UTILS from 'src/utils/uiUtils';
import useGetSlippageForMarket from './useGetSlippageForMarket';
import { TRADE_PREP_UTILS } from 'src/stores/DriftStore/actions/actionHelpers/orderPrep';

export type PreviewOrderParams = {
	auctionStartPrice: BN;
	auctionEndPrice: BN;
	auctionDuration: number;
	slippage: number;
	constrainedBySlippage?: boolean;
	isOracleOrder?: boolean;
	isDivergentFromOracle?: boolean;
};

/* Singleton hook to fetch the current auction params based on TradeForm inputs. */
/* If oracle order, it will automatically return the offsets to display more digestible values to the user  */
const useTradeFormPreviewOrder = (): PreviewOrderParams => {
	const tradeForm = useDriftStore((s) => s.tradeForm);
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);

	const getMarketInfo = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);

	const getSlippageForMarket = useGetSlippageForMarket();
	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);
	const markPrice = useMarkPrice(selectedMarketId);

	const [previewOrderParams, setPreviewOrderParams] =
		useState<PreviewOrderParams>();

	const priceImpactInfo = useTradeformPriceImpact();

	const {
		slippageTolerance,
		baseSizeStringValue,
		maxLeverageSelected,
		orderType,
		side,
	} = tradeForm;

	useEffect(() => {
		if (
			orderType !== 'market' &&
			orderType !== 'oracle' &&
			orderType !== 'limit'
		) {
			return;
		}

		const currentSettings = syncGetCurrentSettings();

		// return if prices/settings not loaded yet
		if (!currentSettings) return;
		if (
			(!oraclePrice || oraclePrice?.eqZero()) &&
			(!markPrice || markPrice?.eqZero())
		)
			return;

		try {
			const marketAccount = getMarketInfo(
				selectedMarketId.marketIndex,
				selectedMarketId.marketType
			)?.account;

			const isCustomSlippage = UI_UTILS.isCustomSlippage(
				slippageTolerance,
				selectedMarketId
			);

			const slippageToleranceToUse = getSlippageForMarket(
				selectedMarketId,
				slippageTolerance,
				tradeForm.side === 'buy'
					? PositionDirection.LONG
					: PositionDirection.SHORT,
				priceImpactInfo
			);

			const auctionParams = COMMON_UI_UTILS.deriveMarketOrderParams({
				marketType: selectedMarketId.marketType,
				marketIndex: selectedMarketId.marketIndex,
				direction:
					tradeForm.side === 'buy'
						? PositionDirection.LONG
						: PositionDirection.SHORT,
				baseAmount: tradeForm.maxLeverageSelected
					? MAX_LEVERAGE_ORDER_SIZE
					: BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP).val,
				reduceOnly: tradeForm.reduceOnly,
				allowInfSlippage: slippageTolerance == undefined,
				worstPrice: priceImpactInfo.worstPrice,
				auctionDuration:
					currentSettings.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
				auctionStartPriceOffset:
					TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
						currentSettings.auctionStartPriceOffset,
						selectedMarketId
					),
				auctionEndPriceOffset: currentSettings.auctionEndPriceOffset,
				maxLeverageSelected: maxLeverageSelected,
				maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
				oraclePrice: oraclePrice?.val,
				markPrice: markPrice?.val,
				bestPrice: priceImpactInfo.bestPrice,
				entryPrice: priceImpactInfo.entryPrice,
				auctionStartPriceOffsetFrom:
					TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
						currentSettings.auctionStartPriceOffsetFrom,
						selectedMarketId
					),
				auctionEndPriceOffsetFrom: currentSettings.auctionEndPriceOffsetFrom,
				slippageTolerance: slippageToleranceToUse,
				isOracleOrder: currentSettings.oracleOffsetOrdersEnabled,
				additionalEndPriceBuffer: UI_UTILS.getAdditionalEndPriceBuffer(
					marketAccount as PerpMarketAccount
				),
				forceUpToSlippage: isCustomSlippage,
			});

			// if it was a limit order and not crossing, or auctions are disabled, return nothing
			if (!auctionParams?.auctionDuration) return;

			const isOracleOrder = ENUM_UTILS.match(
				auctionParams.orderType,
				OrderType.ORACLE
			);

			const auctionStartPrice = isOracleOrder
				? oraclePrice?.val.add(auctionParams.auctionStartPrice)
				: auctionParams.auctionStartPrice;

			const auctionEndPrice = isOracleOrder
				? oraclePrice?.val.add(auctionParams.auctionEndPrice)
				: auctionParams.auctionEndPrice;

			const thresholdPct = BigNum.from(
				priceImpactInfo.entryPrice,
				PRICE_PRECISION_EXP
			).scale(Env.priceDivergenceWarningThreshold, 100);

			const oracleDivergence = BigNum.from(
				auctionEndPrice.sub(oraclePrice.val),
				PRICE_PRECISION_EXP
			).abs();

			const params = {
				auctionStartPrice,
				auctionEndPrice,
				auctionDuration: auctionParams.auctionDuration,
				constrainedBySlippage: auctionParams.constrainedBySlippage,
				isOracleOrder,
				isDivergentFromOracle:
					(orderType === 'market' || orderType === 'oracle') &&
					baseSizeStringValue &&
					oracleDivergence.gt(thresholdPct),
				slippage: slippageToleranceToUse,
			};

			setPreviewOrderParams(params);
		} catch (e) {
			console.error(`Error setting preview auction params: `, e);
		}
	}, [
		oraclePrice,
		markPrice,
		side,
		baseSizeStringValue,
		maxLeverageSelected,
		orderType,
		priceImpactInfo,
		slippageTolerance,
		selectedMarketId.key,
	]);

	return previewOrderParams;
};

export default singletonHook(
	(): PreviewOrderParams => undefined,
	useTradeFormPreviewOrder
);
