'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	convertToNumber,
	MarketType,
	PositionDirection,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION,
	QUOTE_PRECISION_EXP,
	TEN_THOUSAND,
	ZERO,
	SpotMarketConfig,
	SpotMarketAccount,
	PerpMarketAccount,
	MarketStatus,
} from '@drift-labs/sdk';
import {
	Info,
	Add,
	Edit,
	Wallet,
	Settings,
	ChevronRight,
	Success,
	Gas,
} from '@drift-labs/icons';
import { usePathname } from 'next/navigation';
import BuySellToggle from 'src/components/Buttons/BuySellToggle';
import SwitchInput from 'src/components/Inputs/SwitchInput';
import Utility from 'src/components/Inputs/Utility';
import Slider from 'src/components/Slider';
import SliderButtonComboInput from 'src/components/SliderButtonComboInput/SliderButtonComboInput';
import Text from 'src/components/Text/Text';
import Env, {
	marketTickSizes,
} from 'src/environmentVariables/EnvironmentVariables';
import useAccountData, { useTickedAccountData } from 'src/hooks/useAccountData';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDriftActions from 'src/hooks/useDriftActions';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import useUserAccountIsReady from '../../hooks/useUserAccountIsReady';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import Button from '../Button';
import OnboardingTooltip from '../Tooltip/OnboardingTooltip';
import AdvancedPerpTradeSettings from './AdvancedTradeSettings';
import AdvancedSpotTradeSettings from './AdvancedSpotTradeSettings';
import BracketOrderForm, { TriggerOrderInfo } from './BracketOrderForm';
import InfoMessage from './InfoMessage';
import SlippageToleranceDisplay from './SlippageToleranceDisplay';
import TradeFormInputLabel from './TradeFormInputLabel';
import useTradeFormInputStateEngine from './useTradeFormInputStateEngine';
import { useSpotTradeFormStateEngine } from './useTradeFormStateEngine';
import { usePerpTradeFormStateEngine } from './useTradeFormStateEngine';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import useMaxMarginRatioAndLeverage from 'src/hooks/useMaxMarginRatioAndLeverage';
import {
	ENUM_UTILS,
	MAIN_POOL_ID,
	MarketId,
	matchEnum,
	UI_ORDER_TYPES_LIST,
	UIMarket,
} from '@drift/common';
import PriceDivergenceWarningMessage from '../PriceDivergenceWarningMessage';
import SettingsSwitch from '../Settings/SettingsSwitch';
// import { useWindowSizeStore } from 'src/stores/useWindowSizeStore';
import {
	FieldSplitter,
	MarkPriceDisplay,
	OrderTypeSelector,
	PerpTradeFormPrimaryButton,
	postOnlyAndReduceOnlyNotAllowedTooltip,
	postOnlyTooltip,
	reduceOnlyTooltip,
	PriceBox,
	SizeSelector,
	ValuesSelector,
} from './TradeFormPieces';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import {
	MarkPriceLite,
	LeverageLabelLite,
	LimitPriceBoxLite,
	MarketLimitToggle,
	PredictionMarketYesAndNoPriceDisplay,
	SizeSelectorLite,
} from './TradeFormLitePieces';
import BlurOverlayInfoMessage from '../Utils/BlurOverlay/BlurOverlayInfoMessage';
import Link from '../Utils/Link';
import BlurOverlay from '../Utils/BlurOverlay/BlurOverlay';
import useInfoForCurrentlySelectedMarket, {
	MarketInfo,
} from '../../hooks/useInfoForCurrentlySelectedMarket';
import useDriftAccountStore from '../../stores/useDriftAccountsStore';
import {
	getIsIsolatedTierMarket,
	shouldBlockTradingForNonIsolatedMarket,
	shouldBlockTradingForIsolatedMarket,
} from '../PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import { twMerge } from 'tailwind-merge';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import PoweredByOpenBookAndPhoenix from '../Utils/PoweredByOpenbookAndPhoenix';
import useSyncPrePreparedPerpTradeFormOrder from 'src/hooks/TransactionPrep/useSyncPrePreparedPerpTradeFormOrder';
import usePrePreppedTxStore from 'src/stores/usePrePreppedTxStore';
import YesNoToggle from 'src/components/Buttons/YesNoToggle';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';
import { invertPriceImpact } from 'src/hooks/usePriceImpact';
import {
	useSetTradeFormBaseSize,
	useSetTradeFormPriceBox,
	useSetTradeFormQuoteSize,
	useSetTradeFormSecondaryPriceBox,
} from 'src/components/TradeForm/useSetTradeFormInputState';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { GET_API_ROUTE } from 'src/constants/api';
import { MAGIC_EDEN_WALLET_NAME } from 'src/constants/wallets';
import Tooltip from '../Tooltip/Tooltip';
import useMarketMaxLeverage from 'src/hooks/useMarketMaxLeverages';
import { InvariantChecker } from 'src/utils/UIInvariants/InvariantChecker';
import { INVARIANT_CHECKER_INPUT_IDS } from 'src/utils/UIInvariants/constants';
import useMarkPrice from 'src/hooks/useMarkPrice';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';
import { SwiftToggleSwitch } from './SwiftToggleSwitch';
import { Typo } from '../Text/Typo';
import { useSignedMsgOrdersEnablementStatus } from 'src/hooks/signedMsgOrders/useSignedMsgOrdersEnablementStatus';
import { useMarketOracleEnabledForSignedMsgOrders } from 'src/hooks/signedMsgOrders/useMarketOracleEnabledForSignedMsgOrders';

type IsolatedMarketContentBlockType =
	| 'isolated-market-block'
	| 'non-isolated-market-block';

const BLUR_OVERLAY_PARENT_CLASSES = ''; // Styles to be applied to the parent of the PreLaunchMarketContentBlock
const BLUR_OVERLAY_SIBLING_CLASSES = 'relative z-10'; // Styles to be applied to the sibling (the trade form content being blocked) of the PreLaunchMarketContentBlock
const ACTIVE_BLUR_OVERLAY_SIBLING_CLASSES = 'opacity-30'; // Styles to be applied to the sibling (the trade form content being blocked) of the PreLaunchMarketContentBlock

export const IsolatedMarketContentBlock = (props: {
	blockType: IsolatedMarketContentBlockType;
	ticker?: string;
	marketId: MarketId;
	hasMultipleSubaccounts?: boolean;
}) => {
	const actions = useDriftActions();
	const setDrawersStore = useDrawersStore((s) => s.set);

	const handleOpenAddAccountModal = () => {
		actions.showModal('showNewSubaccountModal');
	};

	const handleOpenAccountDrawer = () => {
		setDrawersStore((s) => {
			s.accounts.show = true;
		});
	};

	const handleOpenMarginSettings = () => {
		actions.showModal('showMarginModal');
	};

	if (!props) return;

	const switchMarketCTA =
		props.blockType === 'non-isolated-market-block' && props.marketId ? (
			<>
				<Utility.VERTSPACERM />
				<Button.Ghost
					className="w-full"
					size="MEDIUM"
					onClick={() =>
						actions.switchMarket({
							marketIndex: props.marketId.marketIndex,
							marketType: props.marketId.marketType,
						})
					}
				>
					Trade {props.ticker}
				</Button.Ghost>
			</>
		) : null;

	const switchAccountsCTA = props.hasMultipleSubaccounts ? (
		<>
			<Button.Secondary
				className="w-full"
				size="MEDIUM"
				onClick={handleOpenAccountDrawer}
			>
				Switch Subaccounts
			</Button.Secondary>
			{switchMarketCTA}
		</>
	) : (
		<>
			<Button.Secondary
				className="w-full"
				size="MEDIUM"
				onClick={handleOpenAddAccountModal}
			>
				Create a Subaccount
			</Button.Secondary>
			{switchMarketCTA}
		</>
	);

	return (
		<BlurOverlay
			showBlur={true}
			overlayContent={
				<div className="flex justify-center w-full h-full text-left">
					{props.blockType === 'non-isolated-market-block' ? (
						<>
							<BlurOverlayInfoMessage
								type="info"
								messageTitle={`How to Trade this Market`}
								message={
									<div className="flex flex-col w-full h-full">
										<div>
											This account has a position or order open in a market (
											{props.ticker}) where{' '}
											<Link
												className="inline-flex"
												href="https://docs.drift.trade/trading/market-specs#isolated"
											>
												Isolated Margin
											</Link>{' '}
											from other markets is required as a safety precaution.
										</div>
										<br />
										<div>
											To trade this market, switch to another subaccount or
											close your {props.ticker} position.
										</div>
										<Utility.VERTSPACERXL />
										{switchAccountsCTA}
									</div>
								}
							/>
						</>
					) : (
						<>
							<BlurOverlayInfoMessage
								type="info"
								messageTitle={`Trading Disabled`}
								message={
									<div className="flex flex-col">
										<div>
											To trade Pre-Launch Markets,{' '}
											<Link
												className="inline-flex"
												href={
													'https://docs.drift.trade/trading/market-specs#isolated'
												}
											>
												<span>Isolated Margin</span>
											</Link>{' '}
											is required:
										</div>
										<ul>
											<li>
												• Close any open positions, orders, or non-USDC borrows
											</li>
											<li>• Claim any unsettled P&L</li>
											<li
												className="hover:cursor-pointer text-interactive-link"
												onClick={handleOpenMarginSettings}
											>
												• Disable margin trading
											</li>
										</ul>
										<br />
										<div>
											Alternatively, you can create or switch to a different
											subaccount to trade.
										</div>
										<Utility.VERTSPACERXL />
										{switchAccountsCTA}
									</div>
								}
							/>
						</>
					)}
				</div>
			}
			zIndex={20}
		/>
	);
};

export const IsolatedPoolsContentBlock = (props: {
	hasMultipleSubaccounts?: boolean;
}) => {
	const actions = useDriftActions();
	const setDrawersStore = useDrawersStore((s) => s.set);

	const handleOpenAddAccountModal = () => {
		actions.showModal('showNewSubaccountModal');
	};

	const handleOpenAccountDrawer = () => {
		setDrawersStore((s) => {
			s.accounts.show = true;
		});
	};

	if (!props) return;

	const switchAccountsCTA = props.hasMultipleSubaccounts ? (
		<>
			<button onClick={handleOpenAccountDrawer} className="mx-auto mt-2">
				<Text.BODY3 className="flex flex-row items-center gap-2 text-text-secondary">
					Switch Account
					<ChevronRight />
				</Text.BODY3>
			</button>
		</>
	) : (
		<>
			<button onClick={handleOpenAddAccountModal} className="mx-auto mt-2">
				<Text.BODY3 className="flex flex-row items-center gap-2 text-text-secondary">
					Create a Subaccount
					<ChevronRight />
				</Text.BODY3>
			</button>
		</>
	);

	return (
		<BlurOverlay
			showBlur={true}
			overlayContent={
				<div className="flex justify-center w-full h-full text-left">
					<BlurOverlayInfoMessage
						type="warn"
						messageTitle={`Trading Disabled`}
						message={
							<div className="flex flex-col leading-3 text-center">
								<div className="m-2">
									<Text.BODY3>
										Trading is disabled in isolated pool subaccounts.
									</Text.BODY3>
								</div>
								<div className="m-2">
									<Text.BODY3>
										{props.hasMultipleSubaccounts
											? 'Switch to the main account or another subaccount to trade.'
											: 'Create another subaccount to trade.'}
									</Text.BODY3>
								</div>
								{switchAccountsCTA}
							</div>
						}
					/>
				</div>
			}
			zIndex={20}
		/>
	);
};

const useShouldShowIsolatedMarketContentBlock = (): {
	blockType: IsolatedMarketContentBlockType | null;
	preLaunchTickerName: string | null;
	preLaunchMarketId: MarketId | null;
} => {
	const selectedMarket = useInfoForCurrentlySelectedMarket();
	const currentUserClient = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.client
	);
	const getMarketInfo = useMarketsInfoStore()?.getMarketInfoByIndexAndType;

	if (!selectedMarket?.info?.marketId)
		return {
			blockType: null,
			preLaunchTickerName: null,
			preLaunchMarketId: null,
		};

	const isPreLaunchMarket = getIsIsolatedTierMarket(
		selectedMarket.info.marketId,
		selectedMarket.info.account
	);

	if (isPreLaunchMarket) {
		if (
			shouldBlockTradingForIsolatedMarket(
				currentUserClient?.getUserAccount(),
				getMarketInfo
			)
		) {
			return {
				blockType: 'isolated-market-block',
				preLaunchTickerName: null,
				preLaunchMarketId: null,
			};
		}
	} else {
		if (
			shouldBlockTradingForNonIsolatedMarket(
				currentUserClient?.getUserAccount(),
				getMarketInfo
			)
		) {
			const preLaunchMarketPosition =
				currentUserClient?.getActivePerpPositions()[0];

			return {
				blockType: 'non-isolated-market-block',
				preLaunchTickerName: getMarketInfo(
					preLaunchMarketPosition.marketIndex,
					MarketType.PERP
				)?.genericInfo.symbol,
				preLaunchMarketId: MarketId.createPerpMarket(
					preLaunchMarketPosition.marketIndex
				),
			};
		}
	}

	return {
		blockType: null,
		preLaunchTickerName: null,
		preLaunchMarketId: null,
	};
};

const PerpTradeForm = () => {
	const isliteMode = useIsLiteMode();
	const isMobile = useIsMobileScreenSize();
	const tradeFormState = usePerpTradeFormStateEngine();
	const numberOfSubAccounts = useLazySubAccounts()?.length ?? 0;
	const { captureEvent } = usePostHogCapture();
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const setDrawers = useDrawersStore((s) => s.set);

	// This hook keeps the input values in the drift store in sync
	useTradeFormInputStateEngine();
	useSyncPrePreparedPerpTradeFormOrder();

	const set = useDriftStore((s) => s.set);
	const connected = useWalletIsConnected();

	const isInDepositAndTradeState = tradeFormState.isInDepositAndTradeState;
	const tradeFormDisabled = isInDepositAndTradeState ? false : !connected;

	const selectedMarketInfo = useInfoForCurrentlySelectedMarket() as MarketInfo;
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const selectedMarketSymbol = useDriftStore(
		(s) => s.selectedMarket.current.symbol
	);
	const selectedMarketBaseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol()
	);
	const currentAccount = useAccountData();
	const maxMarginRatio = useMaxMarginRatioAndLeverage()[0];
	const {
		blockType: shouldShowIsolatedMarketContentBlock,
		preLaunchTickerName,
		preLaunchMarketId,
	} = useShouldShowIsolatedMarketContentBlock();

	const shouldShowIsolatedPoolsContentBlock =
		typeof currentAccount?.client?.getUserAccount()?.poolId === 'number' &&
		currentAccount?.client?.getUserAccount()?.poolId !== MAIN_POOL_ID;

	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);

	const actions = useDriftActions();

	const pathname = usePathname();

	const setCurrentOrderType = (orderType: string) =>
		set((s) => {
			//@ts-ignore
			s.tradeForm.orderType = orderType;
		});

	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const showTradeConfirmation =
		savedSettings.showTradeConfirmation && !isliteMode;

	const userAccountIsReady = useUserAccountIsReady();

	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);

	const updateShowBracketOrderForm = useCallback((newValue: boolean) => {
		set((s) => {
			s.tradeForm.showBracketOrderForm = newValue;
		});
	}, []);

	const [isSendingTransaction, setIsSendingTransaction] = useState(false);

	// Only used for lite mode to show/hide the form
	const [isLiteModeTpSlExpanded, setIsLiteModeTpSlExpanded] = useState(false);

	const hideOrderForm = useCallback(
		() => updateShowBracketOrderForm(false),
		[]
	);

	const currentPositionSize = NumLib.formatBn.fromQuote(
		tradeFormState.currentPosition?.quoteAssetAmount ?? new BN(0)
	);

	const currentPositionBazeSize = BigNum.from(
		(tradeFormState.currentPosition?.baseAssetAmount ?? new BN(0)).abs(),
		BASE_PRECISION_EXP
	);

	const currentPositionBazeSizeString =
		currentPositionBazeSize.toTradePrecision();

	const totalCollateral =
		currentAccount?.marginInfo?.totalCollateral ?? new BN(0);
	const freeCollateral =
		currentAccount?.marginInfo?.freeCollateral ?? new BN(0);

	const totalCurrentPositionSize = NumLib.formatBn.fromQuote(
		totalCollateral
			.sub(freeCollateral)
			.mul(new BN(tradeFormState.maxLeverageAvailable))
	);

	const currentLeverage = currentAccount?.marginInfo?.leverage ?? 0;

	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);

	const currentMarketSymbol = selectedMarketSymbol;
	const formattedMarketSymbol = selectedMarketBaseAssetSymbol;

	const tickSize =
		marketTickSizes[formattedMarketSymbol as keyof typeof marketTickSizes] ??
		0.01;

	const toggleReduceOnly = (updatedReduceOnly: boolean) => {
		if (updatedReduceOnly && rawBaseValue.gt(currentPositionBazeSize)) {
			handleFormChangeValue(currentPositionBazeSize.toTradePrecision(), 'base');
		}

		set((s) => {
			s.tradeForm.reduceOnly = updatedReduceOnly;
		});
	};
	const togglePostOnly = () => {
		if (!postOnly) {
			set((s) => {
				s.tradeForm.immediateOrCancel = false;
			});
		}

		set((s) => {
			s.tradeForm.postOnly = !s.tradeForm.postOnly;
		});
	};

	const toggleSwift = (checked: boolean) => {
		set((s) => {
			s.tradeForm.isSwiftSelected = checked;
		});
	};

	const {
		side,
		leadSide,
		quoteSizeStringValue,
		baseSizeStringValue,
		reduceOnly,
		postOnly,
		stepSize,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		orderCount,
		sizeDistribution,
		selectedMarket,
	} = useDriftStore((s) => ({
		side: s.tradeForm.side,
		quoteSizeStringValue: s.tradeForm.quoteSizeStringValue,
		baseSizeStringValue: s.tradeForm.baseSizeStringValue,
		leadSide: s.tradeForm.leadSide,
		slippageTolerance: s.tradeForm.slippageTolerance,
		reduceOnly: s.tradeForm.reduceOnly,
		postOnly: s.tradeForm.postOnly,
		immediateOrCancel: s.tradeForm.immediateOrCancel,
		stepSize: s.tradeForm.stepSize,
		priceBoxStringValue: s.tradeForm.priceBoxStringValue,
		secondaryPriceBoxStringValue: s.tradeForm.secondaryPriceBoxStringValue,
		orderCount: s.tradeForm.scaledOrders.orderCount,
		sizeDistribution: s.tradeForm.scaledOrders.sizeDistribution,
		selectedMarket: s.selectedMarket,
	}));

	const marketOracle = selectedMarket.current.market.oracleSource;
	const selectedMarketOracleEnabledForSignedMsgOrders =
		useMarketOracleEnabledForSignedMsgOrders(marketOracle);

	const swiftEnablementStatus = useSignedMsgOrdersEnablementStatus();
	const showSwiftSwitch =
		swiftEnablementStatus.status !== 'hidden' &&
		selectedMarketOracleEnabledForSignedMsgOrders;

	const displayPrecision = `${stepSize}`.split('.')[1]?.length ?? 2;

	const rawQuoteValue = Number(quoteSizeStringValue);
	const bigNumQuoteValue = BigNum.fromPrint(
		quoteSizeStringValue.toString(),
		QUOTE_PRECISION_EXP
	);
	const rawBaseValue = BigNum.fromPrint(
		baseSizeStringValue.toString(),
		BASE_PRECISION_EXP
	);

	const maxBaseSizeString =
		tradeFormState.maxBaseAvailable.toFixed(displayPrecision);

	const markPrice = useMarkPrice(selectedMarketId);

	let leverageAfterTrade;
	try {
		leverageAfterTrade =
			userAccountIsReady && currentAccount
				? convertToNumber(
						currentAccount.client.accountLeverageRatioAfterTrade(
							selectedMarketId.marketIndex,
							MarketType.PERP,
							NumLib.formatNum.toRawBn(rawQuoteValue, QUOTE_PRECISION),
							side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
						),
						TEN_THOUSAND
				  )
				: 0;
	} catch (e) {
		console.error(e);
		leverageAfterTrade = 0;
	}

	const setPriceBoxStringValue = useSetTradeFormPriceBox();
	const setSecondaryPriceBoxStringValue = useSetTradeFormSecondaryPriceBox();
	const setQuoteFormValue = useSetTradeFormQuoteSize();
	const setBaseSizeStringValue = useSetTradeFormBaseSize();

	const setBaseFormValue = (newBaseSize: string) => {
		const rounded = UI_UTILS.roundToStepSizeIfLargeEnough(
			newBaseSize,
			stepSize
		);

		setBaseSizeStringValue(rounded);
	};

	const saveBracketOrdersCallback = useCallback(
		(
			bracketOrders: {
				takeProfit: TriggerOrderInfo;
				stopLoss: TriggerOrderInfo;
			},
			keepFormOpen = false
		) => {
			if (!keepFormOpen) {
				updateShowBracketOrderForm(false);
			}
			set((s) => {
				s.tradeForm.bracketOrders = bracketOrders;
			});
		},
		[]
	);

	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const outputParams = useMemo(
		() => ({
			...tradeFormStoreState,
			isSwiftSelected:
				swiftEnablementStatus.status === 'enabled' &&
				tradeFormStoreState.isSwiftSelected,
			targetMarketIndex: selectedMarketId?.marketIndex,
			oraclePrice: oraclePrice?.val,
			markPrice: markPrice?.val,
			perpMarketAccount: selectedMarketInfo?.info?.account as PerpMarketAccount,
		}),
		[
			tradeFormStoreState,
			selectedMarketId?.marketIndex,
			oraclePrice?.val,
			markPrice?.val,
			selectedMarketInfo?.info?.account,
		]
	);

	const prePreparedTx = usePrePreppedTxStore((s) => s.preppedPerpTradeformTxs);

	async function onSubmit() {
		try {
			if (isInDepositAndTradeState) {
				set((s) => {
					s.tradeForm.isInDepositToTradeFlow = true;
				});
				actions.showModal('showDepositToTradeModal', true);
				return;
			}

			// hardcode 2 seconds diable after submit
			setIsSendingTransaction(true);
			setTimeout(() => {
				setIsSendingTransaction(false);
			}, 3000);

			if (!connected) {
				set((s) => {
					s.wallet.lastRouteBeforeConnect = pathname;
				});
				showConnectWalletOptions(true);
				return;
			}

			if (showTradeConfirmation) {
				actions.showModal('showTradeConfirmationModal', true);
				return;
			} else {
				set((s) => {
					s.modals.showTradeFormModal = false;
				});
				captureEvent('opening_new_position', {
					market_type: 'perps',
					trade_base: +outputParams.baseSizeStringValue,
					trade_notional: +outputParams.quoteSizeStringValue,
					market_symbol: selectedMarketSymbol,
				});

				const isTxnSent = await actions.openPerpTradeFormOrder(
					outputParams,
					'perp-trade-form',
					prePreparedTx
				);
				isTxnSent && setIsLiteModeTpSlExpanded(false);
			}
		} finally {
			//
		}
	}

	const setLeadSide = useCallback((side: 'quote' | 'base') => {
		set((s) => {
			s.tradeForm.leadSide = side;
		});
	}, []);

	const setSide = useCallback((side: 'buy' | 'sell') => {
		set((s) => {
			s.tradeForm.side = side;
			s.tradeForm.closingPosition = false;
		});
	}, []);

	/**
	 * Using the same values to render in the field and use as the current value state causes the fields to act as if over-sanitised.
	 * This function handles the value coming back from the tradeforms on change, which will be the string representation of a number, and updates the relevant parts of the state to match.
	 */
	const handleFormChangeValue = (
		value: string,
		fieldName: 'base' | 'quote' | 'percentage' | 'price',
		turnOffClosePosition = true
	) => {
		// dont allow negative values for any fields
		if (value.charAt(0) === '-') {
			value = '0';
		}

		if (value === '') {
			setBaseFormValue('');
			setQuoteFormValue('');
			if (fieldName === 'price') {
				setPriceBoxStringValue('');
			}
			return;
		}

		// don't allow non-number characters
		if (isNaN(parseInt(value.replace('.', '')))) {
			return;
		}

		// Don't allow entering a value longer than 10 digits because it makes the UI look strange. A user needing to type more than 10 digits into either of the size fields is unrealistic anyway
		value = value.slice(0, 10);

		if (turnOffClosePosition) {
			set((s) => {
				s.tradeForm.closingPosition = false;
			});
		}

		switch (fieldName) {
			case 'price': {
				const price = BigNum.fromPrint(value, QUOTE_PRECISION_EXP);

				if (leadSide === 'base') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setQuoteFormValue(price.scalarMul(rawBaseValue).toFixed(2));
					}
				}

				if (leadSide === 'quote') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setBaseFormValue(
							bigNumQuoteValue
								.scale(QUOTE_PRECISION, price.val)
								.toTradePrecision()
						);
					}
				}

				setPriceBoxStringValue(value);
				break;
			}
			case 'base': {
				setLeadSide('base');
				setBaseFormValue(value);
				break;
			}
			case 'quote': {
				setLeadSide('quote');
				setQuoteFormValue(value);
				break;
			}
			case 'percentage': {
				const numericalValue = Math.max(parseFloat(value ?? '0'), 0);
				const percentage = Math.min(Math.max(numericalValue, 0), 100);

				// If current leverage is 1x, slider will already have percentage 20%, but that *actually* means that we're not trying to take any quote size ... adjust for this
				const currentLeverage =
					currentPositionSize /
					((tradeFormState.maxQuoteAvailable + currentPositionSize) / 5);

				const currentLeveragePercentage =
					(currentLeverage / tradeFormState.maxLeverageAvailable) * 100;

				// "available percentage" isn't 100% if we already have leverage .. e.g. if we're already at 50% leverage, only 50% of the slider is available ... adjust quote amount for that
				const availablePercentage = 100 - currentLeveragePercentage;
				const newQuoteSize = Math.max(
					((percentage - currentLeveragePercentage) / availablePercentage) *
						tradeFormState.maxQuoteAvailable,
					0
				);
				setLeadSide('quote');
				setQuoteFormValue(newQuoteSize.toFixed(2));
				break;
			}
		}

		return;
	};

	// const windowHeight = useWindowSizeStore((s) => s.heightString);
	// const windowIsTall = windowHeight === 'tall';

	const onDirectionChange = useCallback((side: 'buy' | 'sell') => {
		setSide(side);
	}, []);

	const onOrderTypeChange = useCallback((newValue: string) => {
		setCurrentOrderType(newValue);
	}, []);

	const orderTypeOptions = useMemo(() => {
		return UI_ORDER_TYPES_LIST.filter(
			(orderType) => orderType.value !== 'oracle'
		);
	}, [UI_ORDER_TYPES_LIST]);

	const onPriceBoxChange = useCallback((value: string) => {
		setPriceBoxStringValue(value);
	}, []);

	const onSecondaryPriceBoxChange = useCallback((value: string) => {
		setSecondaryPriceBoxStringValue(value);
	}, []);

	const onBaseSizeChange = useCallback(
		(value: string) => {
			handleFormChangeValue(value, 'base');
		},
		[stepSize]
	);

	const handleQuoteSizeChange = useCallback(
		(value: string) => handleFormChangeValue(value, 'quote'),
		[]
	);

	const handleToggleShowConfirmation = useCallback(() => {
		setSavedSettings({
			showTradeConfirmation: !showTradeConfirmation,
		});
	}, [showTradeConfirmation]);

	const handleSliderChange = useCallback(
		(v: number) =>
			handleFormChangeValue(
				(Math.floor(v * 10000) / 10000).toFixed(2),
				'quote'
			),
		[]
	);

	const handleOrderCountChange = useCallback(
		(value: number) =>
			set((s) => {
				s.tradeForm.scaledOrders.orderCount = value;
			}),
		[]
	);

	const handleSizeDistributionChange = useCallback(
		(value: 'flat' | 'ascending' | 'descending') =>
			set((s) => {
				s.tradeForm.scaledOrders.sizeDistribution = value;
			}),
		[]
	);

	const setMaxLevSelected = useCallback(
		(newSelection: boolean) =>
			set((s) => {
				s.tradeForm.maxLeverageSelected = newSelection;
			}),
		[]
	);

	const saveLimitPrice = useCallback(
		(value: string) => {
			if (tradeFormState.orderType !== 'limit') return;
			set((s) => {
				s.tradeForm.savedLimitPrice = isNaN(Number(value))
					? undefined
					: {
							market: selectedMarketId,
							price: Number(value),
					  };
			});
		},
		[tradeFormState.orderType, selectedMarketId?.marketIndex]
	);

	const handleToggleLiteModeTpSlExpanded = useCallback(() => {
		setIsLiteModeTpSlExpanded(!isLiteModeTpSlExpanded);
	}, [isLiteModeTpSlExpanded]);

	// slider stays focused when mouse leaves the tradeform while dragging - this fixes by unfocusing it when you re-enter
	const unFocus = () => {
		//@ts-ignore
		if (document.activeElement.role == 'slider') {
			//@ts-ignore
			document.activeElement.blur();
		}
	};

	const handleShowMarginLeverageDrawer = () => {
		setDrawers((s) => {
			s.marginLeverageSettings = {
				show: true,
			};
		});
	};

	const { highLeverageMaxLeverage } = useMarketMaxLeverage(
		MarketType.PERP,
		selectedMarketId.marketIndex
	);

	const isUserInHighLeverageMode =
		currentAccount?.client && currentAccount?.client.isHighLeverageMode();

	const getHighLeverageButton = (
		<div className="flex flex-row items-center gap-2">
			{highLeverageMaxLeverage > 0 ? (
				<Button.Secondary
					size="SMALL"
					className={twMerge(
						'py-1 h-[22px] text-interactive-link flex flex-row items-center gap-0.5',
						!isUserInHighLeverageMode && 'bg-interactive-secondary-bg'
					)}
					onClick={handleShowMarginLeverageDrawer}
				>
					{isUserInHighLeverageMode ? (
						<>
							<Settings color="var(--text-secondary)" size={16} />
						</>
					) : (
						<Text.MICRO1 className="font-semibold">
							Get {highLeverageMaxLeverage}x Leverage
						</Text.MICRO1>
					)}
				</Button.Secondary>
			) : (
				<Tooltip
					content={'High leverage mode is not available for this market'}
					placement="top"
				>
					<Button.Secondary
						size="SMALL"
						disabled
						className={'py-1 h-[22px] text-text-label'}
					>
						{isUserInHighLeverageMode ? (
							<Text.MICRO1 className="font-semibold">
								High Leverage Unavailable
							</Text.MICRO1>
						) : (
							<Text.MICRO1 className="font-semibold">
								Get {Env.perpsMaxHighLeverage}x Leverage
							</Text.MICRO1>
						)}
					</Button.Secondary>
				</Tooltip>
			)}
		</div>
	);

	const submitButton = (
		<PerpTradeFormPrimaryButton
			connected={connected}
			side={side}
			reduceOnly={reduceOnly}
			tradeFormState={tradeFormState}
			tradeFormStoreState={tradeFormStoreState}
			currentMarketSymbol={currentMarketSymbol}
			baseSizeStringValue={baseSizeStringValue}
			stepSize={stepSize}
			showTradeConfirmation={showTradeConfirmation}
			hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
			onSubmit={onSubmit}
			isGeoblocked={isGeoblocked}
			isSendingTransaction={isSendingTransaction}
			leadSide={leadSide}
			rawBaseValue={rawBaseValue}
			isInDepositAndTradeState={isInDepositAndTradeState}
		/>
	);

	// Different rendering for lite mode
	if (isliteMode) {
		return (
			<div
				className={twMerge(BLUR_OVERLAY_PARENT_CLASSES)}
				onMouseLeave={unFocus}
				{...InvariantChecker.getInvariantCheckerContextWrapperHtmlAttributes(
					'perp-trade-form'
				)}
			>
				{shouldShowIsolatedMarketContentBlock && (
					<IsolatedMarketContentBlock
						blockType={shouldShowIsolatedMarketContentBlock}
						ticker={preLaunchTickerName}
						marketId={preLaunchMarketId}
						hasMultipleSubaccounts={numberOfSubAccounts > 1}
					/>
				)}

				{shouldShowIsolatedPoolsContentBlock && (
					<IsolatedPoolsContentBlock
						hasMultipleSubaccounts={numberOfSubAccounts > 1}
					/>
				)}

				<div
					className={twMerge(
						BLUR_OVERLAY_SIBLING_CLASSES,
						(shouldShowIsolatedMarketContentBlock ||
							shouldShowIsolatedPoolsContentBlock) &&
							ACTIVE_BLUR_OVERLAY_SIBLING_CLASSES
					)}
				>
					<MarketLimitToggle
						orderType={tradeFormState.orderType}
						onChange={onOrderTypeChange}
					/>

					<BuySellToggle isPerp={true} onChange={onDirectionChange} />

					<SizeSelectorLite
						baseStepSize={stepSize}
						quoteStepSize={0.01}
						baseSizeStringValue={baseSizeStringValue}
						quoteSizeStringValue={quoteSizeStringValue}
						maxQuote={tradeFormState.maxQuoteAvailable}
						maxBase={tradeFormState.maxBaseAvailable}
						handleChangeSizeBase={onBaseSizeChange}
						handleChangeSizeQuote={handleQuoteSizeChange}
						handleChangeMaxLeverageSelected={setMaxLevSelected}
						isInDepositAndTradeState={isInDepositAndTradeState}
						baseSizeInputId={INVARIANT_CHECKER_INPUT_IDS.baseSizeInput}
					/>

					{isInDepositAndTradeState ? null : (
						<div className="mt-5 mb-2">
							<div className="flex flex-row items-center justify-between mb-2">
								<LeverageLabelLite />
								{getHighLeverageButton}
							</div>
							<div className="h-10 pt-4">
								<SliderButtonComboInput
									currentLeverage={currentLeverage}
									targetTradeQuoteSize={rawQuoteValue}
									totalCurrentPositionSize={totalCurrentPositionSize}
									targetMarketCurrentPositionSize={currentPositionSize}
									sameSide={
										!tradeFormState.currentPosition?.baseAssetAmount ||
										tradeFormState.currentPosition?.baseAssetAmount.eq(ZERO) ||
										(tradeFormState.currentPosition?.baseAssetAmount?.isNeg()
											? 'sell'
											: 'buy') === side
									}
									maxQuoteSize={tradeFormState.maxQuoteAvailable}
									maxLeverage={tradeFormState.maxLeverageAvailable}
									onCurrentSizeChange={handleSliderChange}
									leverageAfterTrade={leverageAfterTrade}
									setMaxLevSelected={setMaxLevSelected}
									showButtons={false}
									largeFont={true}
									disabled={tradeFormDisabled}
								/>
							</div>
						</div>
					)}

					{tradeFormState.orderType === 'market' ? (
						<MarkPriceLite
							value={markPrice?.toNum()}
							warn={tradeFormState.showPriceEstimateOracleDivergenceWarning}
							requireSwitchingToProModeToSeeOrderbook
						/>
					) : (
						<LimitPriceBoxLite
							onChange={setPriceBoxStringValue}
							value={priceBoxStringValue}
							tickSize={tickSize}
						/>
					)}

					{showSwiftSwitch && (
						<div className="flex items-center justify-between">
							<SwiftToggleSwitch
								onChange={toggleSwift}
								value={tradeFormState.isSwiftSelected}
								className="px-0"
							/>
							{tradeFormState.isSwiftSelected && (
								<div className="flex items-center gap-1">
									<Typo.B4 className="flex items-center gap-1 text-text-secondary">
										<Gas />
										Gas fees
									</Typo.B4>
									<div className="flex items-center gap-1 text-positive-green text-xs">
										<Success /> Free
									</div>
								</div>
							)}
						</div>
					)}

					<BracketOrderForm
						direction={tradeFormStoreState.side == 'buy' ? 'long' : 'short'}
						entryPrice={
							tradeFormState.orderType == 'market'
								? BigNum.from(
										tradeFormStoreState.priceImpact.entryPrice,
										PRICE_PRECISION_EXP
								  )
								: BigNum.fromPrint(priceBoxStringValue, PRICE_PRECISION_EXP)
						}
						entrySize={BigNum.fromPrint(
							baseSizeStringValue,
							BASE_PRECISION_EXP
						)}
						goBackCallback={hideOrderForm}
						saveOrdersCallback={saveBracketOrdersCallback}
						savedTakeProfitPrice={
							tradeFormStoreState.bracketOrders?.takeProfit
								? tradeFormStoreState.bracketOrders.takeProfit.price
								: undefined
						}
						savedStopLossPrice={
							tradeFormStoreState.bracketOrders?.stopLoss
								? tradeFormStoreState.bracketOrders.stopLoss.price
								: undefined
						}
						orderType={tradeFormState.orderType}
						assetSymbol={selectedMarketBaseAssetSymbol}
						displayPrecision={displayPrecision}
						isLiteMode={isliteMode}
						isExpanded={isLiteModeTpSlExpanded}
						onToggleExpanded={handleToggleLiteModeTpSlExpanded}
					/>

					{tradeFormState.shouldShowMessage && (
						<div className="my-5">
							<InfoMessage
								type={tradeFormState.messageType}
								message={tradeFormState.message}
								link={tradeFormState.messageLink}
								linkDescription={tradeFormState.messageLinkDescription}
								messageTitle={tradeFormState.messageTitle}
								messageId={tradeFormState.messageId}
							/>
						</div>
					)}

					{tradeFormState.showPriceEstimateOracleDivergenceWarning && (
						<PriceDivergenceWarningMessage
							hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
							setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
						/>
					)}

					<hr className="my-5 border-container-border" />

					<div className="mt-3 mb-5">
						<AdvancedPerpTradeSettings
							orderType={tradeFormState.orderType}
							isInDepositAndTradeState={isInDepositAndTradeState}
							isSignedMsgOrderSelected={tradeFormState.isSwiftSelected}
						/>
					</div>

					{submitButton}
				</div>
			</div>
		);
	}

	return !tradeFormStoreState.showBracketOrderForm ? (
		<>
			<div
				className={twMerge(
					`flex flex-col h-full ${'justify-start'}`,
					BLUR_OVERLAY_PARENT_CLASSES
				)}
				onMouseLeave={unFocus}
				{...InvariantChecker.getInvariantCheckerContextWrapperHtmlAttributes(
					'perp-trade-form'
				)}
			>
				{shouldShowIsolatedMarketContentBlock && (
					<IsolatedMarketContentBlock
						blockType={shouldShowIsolatedMarketContentBlock}
						ticker={preLaunchTickerName}
						marketId={preLaunchMarketId}
						hasMultipleSubaccounts={numberOfSubAccounts > 1}
					/>
				)}

				{shouldShowIsolatedPoolsContentBlock && (
					<IsolatedPoolsContentBlock
						hasMultipleSubaccounts={numberOfSubAccounts > 1}
					/>
				)}

				<div
					className={twMerge(
						'flex flex-col',
						BLUR_OVERLAY_SIBLING_CLASSES,
						shouldShowIsolatedMarketContentBlock &&
							ACTIVE_BLUR_OVERLAY_SIBLING_CLASSES
					)}
				>
					<BuySellToggle isPerp={true} onChange={onDirectionChange} />
					<Utility.VERTSPACERL />
					{isMobile && <MarkPriceDisplay />}
					<OrderTypeSelector
						orderType={tradeFormState.orderType}
						orderTypeOptions={orderTypeOptions}
						onOrderTypeChange={onOrderTypeChange}
						{...tradeFormState}
					/>
					<Utility.VERTSPACERL />
					<FieldSplitter>
						<PriceBox
							priceBoxStringValue={priceBoxStringValue}
							priceBoxDisabledPlaceholder={
								!tradeFormState.priceBoxEnabled && 'Market Price'
							}
							stepAmount={tickSize}
							onPriceBoxChange={onPriceBoxChange}
							onPriceBoxSave={saveLimitPrice}
							priceBoxHeading={
								tradeFormState.orderType === 'scaledOrders'
									? 'Start Price'
									: tradeFormState.priceBoxHeading
							}
						/>

						{tradeFormState.showSecondaryPriceBox && (
							<PriceBox
								priceBoxStringValue={secondaryPriceBoxStringValue}
								stepAmount={tickSize}
								priceBoxHeading={
									tradeFormState.orderType === 'scaledOrders'
										? 'End Price'
										: 'Limit Price'
								}
								onPriceBoxChange={onSecondaryPriceBoxChange}
							/>
						)}
					</FieldSplitter>
					<Utility.VERTSPACERL />
					<SizeSelector
						reduceOnly={reduceOnly}
						baseSizeStringValue={UI_UTILS.roundToStepSizeIfLargeEnough(
							baseSizeStringValue,
							stepSize
						)}
						formattedMarketSymbol={formattedMarketSymbol}
						quoteSizeStringValue={quoteSizeStringValue}
						maxQuoteAvailable={tradeFormState.maxQuoteAvailable}
						maxBaseAvailableString={maxBaseSizeString}
						showMaxButton={tradeFormState.showMaxButton}
						currentPositionBazeSizeString={currentPositionBazeSizeString}
						onBaseSizeChange={onBaseSizeChange}
						handleQuoteSizeChange={handleQuoteSizeChange}
						stepSize={stepSize}
						userHasSelfImposedMax={!!maxMarginRatio && maxMarginRatio !== 0}
						orderType={tradeFormState.orderType}
						isInDepositAndTradeState={isInDepositAndTradeState}
						baseSizeInputId={INVARIANT_CHECKER_INPUT_IDS.baseSizeInput}
					/>

					{tradeFormState.showLeverageSlider && !isInDepositAndTradeState && (
						<>
							<Utility.VERTSPACERS />
							<div className="font-display">
								<SliderButtonComboInput
									currentLeverage={currentLeverage}
									targetTradeQuoteSize={rawQuoteValue}
									totalCurrentPositionSize={totalCurrentPositionSize}
									targetMarketCurrentPositionSize={currentPositionSize}
									sameSide={
										!tradeFormState.currentPosition?.baseAssetAmount ||
										tradeFormState.currentPosition?.baseAssetAmount.eq(ZERO) ||
										(tradeFormState.currentPosition?.baseAssetAmount?.isNeg()
											? 'sell'
											: 'buy') === side
									}
									maxQuoteSize={tradeFormState.maxQuoteAvailable}
									maxLeverage={tradeFormState.maxLeverageAvailable}
									title="Leverage"
									titleButtonNode={getHighLeverageButton}
									onCurrentSizeChange={handleSliderChange}
									leverageAfterTrade={leverageAfterTrade}
									setMaxLevSelected={setMaxLevSelected}
									disabled={tradeFormDisabled}
								/>
							</div>
						</>
					)}

					{tradeFormState.showReducePositionSlider && (
						<>
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-2">
								<TradeFormInputLabel>{'Position Size (%)'}</TradeFormInputLabel>
							</div>
							<Slider
								type="percent"
								disabled={null}
								value={parseFloat(
									currentPositionBazeSize.eqZero()
										? '0'
										: rawBaseValue.toPercentage(currentPositionBazeSize, 4)
								)}
								onDrop={(val) => {
									handleFormChangeValue(
										currentPositionBazeSize.scale(val, 100).printShort(true),
										'base'
									);
								}}
								onMove={(val) => {
									handleFormChangeValue(
										currentPositionBazeSize.scale(val, 100).printShort(true),
										'base'
									);
								}}
								step={1}
								maxButtonTransition={false}
								max={100}
								min={0}
							/>
						</>
					)}

					{tradeFormState.showOrdersSplit && (
						<>
							<Utility.VERTSPACERL />
							<div className="flex justify-between w-full">
								<TradeFormInputLabel>Order Count</TradeFormInputLabel>
							</div>

							<ValuesSelector
								onSelect={handleOrderCountChange}
								selected={orderCount}
								options={[2, 3, 4, 5].map((i) => ({
									value: i,
									title: String(i),
								}))}
							/>
							<Utility.VERTSPACERL />

							<div className="flex justify-between w-full text-text-tertiary">
								<Tooltip
									key={'liq_header'}
									className="items-center"
									content={
										<div className="flex flex-col">
											<div>
												Distribution method terminology:
												<ul className="list-disc list-inside">
													<li className="list-item">
														Flat: All orders will have the same size.
													</li>
													<li>
														Ascending: Order sizes will linearly increase.
													</li>
													<li>
														Descending: Order sizes will linearly decrease.
													</li>
												</ul>
											</div>
											<br />
											<div>
												Linear scale:{' '}
												<span className="font-bold">
													1x, 1.5x, 2x, 2.5x, 3x
												</span>
											</div>
										</div>
									}
									allowHover
								>
									<TradeFormInputLabel>Size Distribution</TradeFormInputLabel>
									&nbsp;
									<Info size={14} />
								</Tooltip>
							</div>
							<ValuesSelector
								onSelect={handleSizeDistributionChange}
								selected={sizeDistribution}
								options={[
									{ value: 'flat', title: 'Flat' },
									{ value: 'ascending', title: 'Ascending' },
									{ value: 'descending', title: 'Descending' },
								]}
							/>
						</>
					)}
					{tradeFormState.showSlippageTolerance && (
						<>
							<Utility.VERTSPACERL />
							<div className="block">
								<SlippageToleranceDisplay />
							</div>
						</>
					)}
					<>
						<Utility.VERTSPACERM />
						<div className="flex flex-wrap gap-2">
							{showSwiftSwitch && (
								<SwiftToggleSwitch
									onChange={toggleSwift}
									value={tradeFormState.isSwiftSelected}
									className="px-0"
								/>
							)}
							{tradeFormState.showReduceOnly && (
								<SwitchInput
									label="Reduce Only"
									checked={reduceOnly && !postOnly}
									onChange={() => toggleReduceOnly(!reduceOnly)}
									tooltip={
										postOnly
											? postOnlyAndReduceOnlyNotAllowedTooltip
											: reduceOnlyTooltip
									}
									disabled={postOnly && tradeFormState.orderType != 'market'}
								/>
							)}
							{tradeFormState.showPostOnly && (
								<SwitchInput
									label="Post"
									checked={postOnly && !reduceOnly}
									onChange={togglePostOnly}
									tooltip={
										reduceOnly
											? postOnlyAndReduceOnlyNotAllowedTooltip
											: postOnlyTooltip
									}
									disabled={reduceOnly}
								/>
							)}
						</div>
					</>

					{(tradeFormState.orderType == 'market' ||
						(tradeFormState.orderType == 'limit' &&
							!tradeFormStoreState.reduceOnly)) &&
						!isGeoblocked &&
						!tradeFormState.isInDepositAndTradeState && (
							<>
								{!tradeFormStoreState.bracketOrders ? (
									<>
										<Utility.VERTSPACERS />
										<Button.Secondary
											size="MEDIUM"
											className="inline-flex w-[55%] text-xs pl-0"
											onClick={() => updateShowBracketOrderForm(true)}
											disabled={
												!tradeFormStoreState.priceImpact.entryPrice ||
												tradeFormStoreState.priceImpact.entryPrice.eq(ZERO) ||
												(tradeFormStoreState.orderType !== 'market' &&
													!tradeFormStoreState.priceBoxStringValue)
											}
											borderRadius=".5rem"
											textClass="text-text-label"
										>
											<Add size={18} />
											<span className="ml-1">Add TP/SL Orders</span>
										</Button.Secondary>
										<Utility.VERTSPACERS />
									</>
								) : (
									<>
										<div className="flex flex-col w-full mt-2 space-y-1">
											<div className="inline-flex justify-between w-full text-xs">
												<Text.BODY3 className="text-text-label">
													Trigger Orders
												</Text.BODY3>
												<div
													className={
														'text-text-label hover:cursor-pointer inline-flex gap-0.5'
													}
													onClick={() => updateShowBracketOrderForm(true)}
												>
													<Edit size={14} />
													<Text.BODY3>Edit</Text.BODY3>
												</div>
											</div>
											{tradeFormStoreState.bracketOrders.takeProfit && (
												<div className="inline-flex justify-between w-full text-xs">
													<Text.BODY3 className="text-positive-green">
														Take Profit
													</Text.BODY3>
													<Text.BODY3 className={'text-text-emphasis'}>
														{tradeFormStoreState.bracketOrders.takeProfit.price.toNotional()}
													</Text.BODY3>
												</div>
											)}
											{tradeFormStoreState.bracketOrders.stopLoss && (
												<div className="inline-flex justify-between w-full text-xs">
													<Text.BODY3 className="text-negative-red">
														Stop Loss
													</Text.BODY3>
													<Text.BODY3 className={'text-text-emphasis'}>
														{tradeFormStoreState.bracketOrders.stopLoss.price.toNotional()}
													</Text.BODY3>
												</div>
											)}
										</div>
										<Utility.VERTSPACERS />
									</>
								)}
							</>
						)}

					{tradeFormState.shouldShowMessage && (
						<>
							<div className="pb-2" />
							<InfoMessage
								type={tradeFormState.messageType}
								message={tradeFormState.message}
								link={tradeFormState.messageLink}
								linkDescription={tradeFormState.messageLinkDescription}
								messageTitle={tradeFormState.messageTitle}
								messageId={tradeFormState.messageId}
							/>

							<div className="pb-2" />
						</>
					)}
				</div>

				<div className={`flex flex-col justify-center`}>
					<hr className="my-4 border-container-border" />

					<AdvancedPerpTradeSettings
						orderType={tradeFormState.orderType}
						isInDepositAndTradeState={isInDepositAndTradeState}
						isSignedMsgOrderSelected={tradeFormState.isSwiftSelected}
					/>

					<Utility.VERTSPACERS />

					{/* Don't show the showTradeConfirmation switch when in depositAndTrade because the UI doesn't open a confirmation window in this flow, it goes into a seperate flow */}
					{!isInDepositAndTradeState && (
						<div className="flex items-left">
							<SettingsSwitch
								label="Show Confirmation"
								checked={showTradeConfirmation}
								onChange={handleToggleShowConfirmation}
								spacedOut
								includeLineSpace={false}
								className="w-full [&>span]:text-[12px]"
							/>
						</div>
					)}

					<Utility.VERTSPACERS />

					{tradeFormState.showPriceEstimateOracleDivergenceWarning &&
						!showTradeConfirmation && (
							<PriceDivergenceWarningMessage
								hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
								setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
							/>
						)}

					<Utility.VERTSPACERM />

					{submitButton}
				</div>
			</div>
		</>
	) : (
		<BracketOrderForm
			direction={tradeFormStoreState.side == 'buy' ? 'long' : 'short'}
			entryPrice={
				tradeFormState.orderType == 'market'
					? BigNum.from(
							tradeFormStoreState.priceImpact.entryPrice,
							PRICE_PRECISION_EXP
					  )
					: BigNum.fromPrint(priceBoxStringValue, PRICE_PRECISION_EXP)
			}
			entrySize={BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP)}
			goBackCallback={hideOrderForm}
			saveOrdersCallback={saveBracketOrdersCallback}
			savedTakeProfitPrice={
				tradeFormStoreState.bracketOrders?.takeProfit
					? tradeFormStoreState.bracketOrders.takeProfit.price
					: undefined
			}
			savedStopLossPrice={
				tradeFormStoreState.bracketOrders?.stopLoss
					? tradeFormStoreState.bracketOrders.stopLoss.price
					: undefined
			}
			orderType={tradeFormState.orderType}
			assetSymbol={selectedMarketBaseAssetSymbol}
			displayPrecision={displayPrecision}
		/>
	);
};

const SpotTradeForm = () => {
	const tradeFormState = useSpotTradeFormStateEngine();

	// This hook keeps the input values in the drift store in sync
	useTradeFormInputStateEngine();

	const set = useDriftStore((s) => s.set);
	const connected = useWalletIsConnected();
	const selectedUiMarket = useDriftStore((s) => s.selectedMarket.current);
	const currentlySelectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const selectedMarket = selectedUiMarket.market;
	const currentAccount = useAccountData();
	const maxMarginRatio = useMaxMarginRatioAndLeverage()[0];
	const isDelegateAccount = currentAccount?.isDelegatedTo;
	const { captureEvent } = usePostHogCapture();
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);
	const userAccountIsReady = useUserAccountIsReady();
	const oraclePrice = useMemoizedOraclePrice(selectedUiMarket.marketId);
	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const actions = useDriftActions();

	const pathname = usePathname();

	const isMobile = useIsMobileScreenSize();

	const setCurrentOrderType = (orderType: string) =>
		set((s) => {
			//@ts-ignore
			s.tradeForm.orderType = orderType;
		});

	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const { showTradeConfirmation } = savedSettings;

	const userHasMarginEnabled = currentAccount?.marginEnabled ?? false;

	const [showMarginTradingMessage, setShowMarginTradingMessage] =
		useLocalStorageState(
			'showMarginTradingMessage',
			!userHasMarginEnabled ? true : false
		);
	const [isSendingTransaction, setIsSendingTransaction] = useState(false);

	const currentPosition = useAccountTargetSpotBalance(
		selectedMarket as SpotMarketConfig,
		currentAccount?.userKey ?? ''
	);

	const currentPositionSize =
		currentPosition?.netQuoteValue?.toNum() ?? undefined;

	const currentPositionBaseSize =
		currentPosition?.netBaseBalance?.abs() ?? undefined;

	const currentPositionDirection =
		currentPositionBaseSize?.gtZero() ?? true ? 'long' : 'short';

	const basePrecisionToUse = (selectedMarket as SpotMarketConfig).precisionExp;

	const currentPositionBazeSizeString =
		currentPositionBaseSize.toTradePrecision();

	const totalCollateral =
		currentAccount?.marginInfo?.totalCollateral ?? new BN(0);
	const freeCollateral =
		currentAccount?.marginInfo?.freeCollateral ?? new BN(0);

	const totalCurrentPositionSize = NumLib.formatBn.fromQuote(
		totalCollateral
			.sub(freeCollateral)
			.mul(new BN(tradeFormState.maxLeverageAvailable))
	);

	const currentLeverage = currentAccount?.marginInfo?.leverage ?? 0;

	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);

	const currentMarketSymbol = selectedUiMarket.symbol;
	const formattedMarketSymbol = selectedUiMarket.baseAssetSymbol();

	const tickSize =
		marketTickSizes[formattedMarketSymbol as keyof typeof marketTickSizes] ??
		0.01;

	const toggleReduceOnly = (updatedReduceOnly: boolean) => {
		if (!reduceOnly) {
			if (rawBaseValue.gt(currentPositionBaseSize)) {
				handleFormChangeValue(
					currentPositionBaseSize.toTradePrecision(),
					'base'
				);
			}
		}
		// disable this for now, so user can make order post only
		// else if (side === 'sell' && !userHasMarginEnabled) {
		// 	// cant turn off reduce only if selling and margin disabled, ensure it's on
		// 	set((s) => {
		// 		s.tradeForm.reduceOnly = true;
		// 	});

		// 	return;
		// }

		set((s) => {
			s.tradeForm.reduceOnly = updatedReduceOnly;
		});
	};
	const togglePostOnly = () => {
		if (!postOnly) {
			set((s) => {
				s.tradeForm.immediateOrCancel = false;
			});
		}

		set((s) => {
			s.tradeForm.postOnly = !s.tradeForm.postOnly;
		});
	};

	useEffect(() => {
		// scaledOrders is not support on spot yet, hence switch to default limit
		if (tradeFormState.orderType === 'scaledOrders') {
			setCurrentOrderType('limit');
		}
	}, [tradeFormState.orderType]);

	const {
		side,
		leadSide,
		quoteSizeStringValue,
		baseSizeStringValue,
		reduceOnly,
		postOnly,
		stepSize,
		//immediateOrCancel,
	} = useDriftStore((s) => ({
		side: s.tradeForm.side,
		quoteSizeStringValue: s.tradeForm.quoteSizeStringValue,
		baseSizeStringValue: s.tradeForm.baseSizeStringValue,
		leadSide: s.tradeForm.leadSide,
		slippageTolerance: s.tradeForm.slippageTolerance,
		reduceOnly: s.tradeForm.reduceOnly,
		postOnly: s.tradeForm.postOnly,
		stepSize: s.tradeForm.stepSize,
		//immediateOrCancel: s.tradeForm.immediateOrCancel,
	}));

	const rawQuoteValue = Number(quoteSizeStringValue);
	const bigNumQuoteValue = BigNum.fromPrint(
		quoteSizeStringValue,
		QUOTE_PRECISION_EXP
	);
	const rawBaseValue = BigNum.fromPrint(
		baseSizeStringValue,
		basePrecisionToUse
	);

	const displayPrecision = `${stepSize}`.split('.')[1]?.length ?? 2;

	const maxBaseSizeString =
		tradeFormState.maxBaseAvailable.toFixed(displayPrecision);

	let leverageAfterTrade;
	try {
		leverageAfterTrade =
			userAccountIsReady && currentAccount
				? convertToNumber(
						currentAccount.client.accountLeverageRatioAfterTrade(
							selectedMarket.marketIndex,
							MarketType.SPOT,
							NumLib.formatNum.toRawBn(rawQuoteValue, QUOTE_PRECISION),
							side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
						),
						TEN_THOUSAND
				  )
				: 0;
	} catch (e) {
		console.error(e);
		leverageAfterTrade = 0;
	}

	const { priceBoxStringValue, secondaryPriceBoxStringValue } = useDriftStore(
		(s) => s.tradeForm
	);

	const setPriceBoxStringValue = useSetTradeFormPriceBox();
	const setSecondaryPriceBoxStringValue = useSetTradeFormSecondaryPriceBox();
	const setQuoteFormValue = useSetTradeFormQuoteSize();
	const setBaseSizeStringValue = useSetTradeFormBaseSize();

	const setBaseFormValue = (newBaseSize: string) => {
		const rounded = UI_UTILS.roundToStepSizeIfLargeEnough(
			newBaseSize,
			stepSize
		);

		setBaseSizeStringValue(rounded);
	};

	const setMaxLevSelected = (newSelection: boolean) =>
		set((s) => {
			s.tradeForm.maxLeverageSelected = newSelection;
		});

	async function onSubmit() {
		try {
			// hardcode 2 seconds diable after submit
			setIsSendingTransaction(true);
			setTimeout(() => {
				setIsSendingTransaction(false);
			}, 3000);

			if (!connected) {
				set((s) => {
					s.wallet.lastRouteBeforeConnect = pathname;
				});
				showConnectWalletOptions(true);
				return;
			}

			if (showTradeConfirmation) {
				actions.showModal('showTradeConfirmationModal', true);
				return;
			} else {
				captureEvent('opening_new_position', {
					market_type: 'spot',
					trade_base: +tradeFormStoreState.baseSizeStringValue,
					trade_notional: +tradeFormStoreState.quoteSizeStringValue,
					market_symbol: currentlySelectedMarketInfo.info.config.symbol,
				});

				await actions.openSpotTradeFormOrder({
					...tradeFormStoreState,
					targetMarketIndex: selectedMarket.marketIndex,
					currentPositionBaseSize: currentPositionBaseSize?.val ?? ZERO,
					currentPositionDirection,
					oraclePrice: oraclePrice.val,
					spotMarketAccount: currentlySelectedMarketInfo.info
						.account as SpotMarketAccount,
				});
			}
		} finally {
			//
		}
	}

	const setLeadSide = (side: 'quote' | 'base') => {
		set((s) => {
			s.tradeForm.leadSide = side;
		});
	};

	const setSide = (side: 'buy' | 'sell') => {
		set((s) => {
			s.tradeForm.side = side;
			s.tradeForm.closingPosition = false;
		});
	};

	/**
	 * Using the same values to render in the field and use as the current value state causes the fields to act as if over-sanitised.
	 * This function handles the value coming back from the tradeforms on change, which will be the string representation of a number, and updates the relevant parts of the state to match.
	 */
	const handleFormChangeValue = (
		value: string,
		fieldName: 'base' | 'quote' | 'percentage' | 'price',
		turnOffClosePosition = true
	) => {
		// dont allow negative values for any fields
		if (value.charAt(0) === '-') {
			value = '0';
		}

		if (value === '') {
			setBaseFormValue('');
			setQuoteFormValue('');
			if (fieldName === 'price') {
				setPriceBoxStringValue('');
			}
			return;
		}

		// don't allow non-number characters
		if (isNaN(parseInt(value.replace('.', '')))) {
			return;
		}

		// Don't allow entering a value longer than 10 digits because it makes the UI look strange. A user needing to type more than 10 digits into either of the size fields is unrealistic anyway
		value = value.slice(0, 10);

		if (turnOffClosePosition) {
			set((s) => {
				s.tradeForm.closingPosition = false;
			});
		}

		switch (fieldName) {
			case 'price': {
				const price = BigNum.fromPrint(value, QUOTE_PRECISION_EXP);

				if (leadSide === 'base') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setQuoteFormValue(price.scalarMul(rawBaseValue).toFixed(2));
					}
				}

				if (leadSide === 'quote') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setBaseFormValue(
							bigNumQuoteValue
								.scale(QUOTE_PRECISION, price.val)
								.toTradePrecision()
						);
					}
				}

				setPriceBoxStringValue(value);
				break;
			}
			case 'base': {
				setLeadSide('base');
				setBaseFormValue(value);
				break;
			}
			case 'quote': {
				setLeadSide('quote');
				setQuoteFormValue(value);
				break;
			}
			case 'percentage': {
				// leverage always 0 so pass direct quote/base value
				if (
					!userHasMarginEnabled ||
					tradeFormState.maxLeverageAvailable === 0
				) {
					setLeadSide('quote');
					setQuoteFormValue('');
					break;
				}

				const numericalValue = Math.max(parseFloat(value ?? '0'), 0);
				const percentage = Math.min(Math.max(numericalValue, 0), 100);

				// If current leverage is 1x, slider will already have percentage 20%, but that *actually* means that we're not trying to take any quote size ... adjust for this
				// const currentLeverage =
				// 	currentPositionSize /
				// 	((tradeFormState.maxQuoteAvailable + currentPositionSize) / 5);

				const currentLeveragePercentage =
					(currentLeverage / tradeFormState.maxLeverageAvailable) * 100;

				// "available percentage" isn't 100% if we already have leverage .. e.g. if we're already at 50% leverage, only 50% of the slider is available ... adjust quote amount for that
				const availablePercentage = 100 - currentLeveragePercentage;
				const newQuoteSize = Math.max(
					((percentage - currentLeveragePercentage) / availablePercentage) *
						tradeFormState.maxQuoteAvailable,
					0
				);
				setLeadSide('quote');
				setQuoteFormValue(newQuoteSize.toFixed(2));
				break;
			}
		}

		return;
	};

	// const windowHeight = useWindowSizeStore((s) => s.heightString);
	// const windowIsTall = windowHeight === 'tall';

	const onDirectionChange = useCallback((side: 'buy' | 'sell') => {
		setSide(side);
		set((s) => {
			s.tradeForm.bracketOrders = {
				takeProfit: undefined,
				stopLoss: undefined,
			};
		});
	}, []);

	const onOrderTypeChange = useCallback((newValue: string) => {
		setCurrentOrderType(newValue);
	}, []);

	const orderTypeOptions = useMemo(() => {
		return UI_ORDER_TYPES_LIST.filter(
			(orderType) =>
				orderType.value !== 'oracle' && orderType.value !== 'scaledOrders'
		);
	}, [UI_ORDER_TYPES_LIST]);

	const onPriceBoxChange = useCallback((value: string) => {
		setPriceBoxStringValue(value);
	}, []);

	const onSecondaryPriceBoxChange = useCallback((value: string) => {
		setSecondaryPriceBoxStringValue(value);
	}, []);

	const onBaseSizeChange = useCallback(
		(value: string) => {
			handleFormChangeValue(value, 'base');
		},
		[stepSize]
	);

	const handleQuoteSizeChange = useCallback(
		(value: string) => handleFormChangeValue(value, 'quote'),
		[]
	);

	const handleToggleShowConfirmation = useCallback(() => {
		setSavedSettings({
			...savedSettings,
			showTradeConfirmation: !showTradeConfirmation,
		});
	}, [showTradeConfirmation]);

	const handleSliderChange = useCallback(
		(v: number) =>
			handleFormChangeValue(
				(Math.floor(v * 10000) / 10000).toFixed(2),
				'quote'
			),
		[]
	);

	const goToMarginSettings = () => {
		actions.showModal('showMarginModal');
		setShowMarginTradingMessage(false);
	};

	const saveLimitPrice = useCallback(
		(value: string) => {
			if (tradeFormState.orderType !== 'limit') return;
			set((s) => {
				s.tradeForm.savedLimitPrice = isNaN(Number(value))
					? undefined
					: {
							market: UIMarket.createSpotMarket(selectedMarket?.marketIndex),
							price: Number(value),
					  };
			});
		},
		[tradeFormState.orderType, selectedMarket?.marketIndex]
	);

	return (
		<>
			<div className={`flex flex-col h-full ${'justify-start'}`}>
				<div
					className={twMerge(
						'flex flex-col',
						'relative z-10' // Setting this to z-10 so that the BlurOverlay is relatively higher than the z-index of the form content
					)}
				>
					<BuySellToggle isPerp={false} onChange={onDirectionChange} />
					<Utility.VERTSPACERL />
					{isMobile && <MarkPriceDisplay />}
					<OrderTypeSelector
						orderType={tradeFormState.orderType}
						orderTypeOptions={orderTypeOptions}
						onOrderTypeChange={onOrderTypeChange}
						{...tradeFormState}
					/>
					<Utility.VERTSPACERL />

					<FieldSplitter>
						{tradeFormState.showSecondaryPriceBox && (
							<PriceBox
								priceBoxStringValue={secondaryPriceBoxStringValue}
								stepAmount={tickSize}
								priceBoxHeading={'Limit Price'}
								onPriceBoxChange={onSecondaryPriceBoxChange}
							/>
						)}
					</FieldSplitter>

					<FieldSplitter>
						<PriceBox
							priceBoxStringValue={priceBoxStringValue}
							priceBoxDisabledPlaceholder={
								!tradeFormState.priceBoxEnabled && 'Market Price'
							}
							stepAmount={tickSize}
							onPriceBoxChange={onPriceBoxChange}
							onPriceBoxSave={saveLimitPrice}
							priceBoxHeading={tradeFormState.priceBoxHeading}
						/>

						{tradeFormState.showSecondaryPriceBox && (
							<PriceBox
								priceBoxStringValue={secondaryPriceBoxStringValue}
								stepAmount={tickSize}
								priceBoxHeading={'Limit Price'}
								onPriceBoxChange={onSecondaryPriceBoxChange}
							/>
						)}
					</FieldSplitter>
					<Utility.VERTSPACERL />
					<SizeSelector
						reduceOnly={reduceOnly}
						baseSizeStringValue={UI_UTILS.roundToStepSizeIfLargeEnough(
							baseSizeStringValue,
							stepSize
						)}
						formattedMarketSymbol={formattedMarketSymbol}
						quoteSizeStringValue={quoteSizeStringValue}
						maxQuoteAvailable={tradeFormState.maxQuoteAvailable}
						maxBaseAvailableString={maxBaseSizeString}
						showMaxButton={tradeFormState.showMaxButton}
						currentPositionBazeSizeString={currentPositionBazeSizeString}
						onBaseSizeChange={onBaseSizeChange}
						handleQuoteSizeChange={handleQuoteSizeChange}
						stepSize={stepSize}
						userHasSelfImposedMax={!!maxMarginRatio && maxMarginRatio !== 0}
						orderType={tradeFormState.orderType}
						isInDepositAndTradeState={false}
					/>

					<>
						<Utility.VERTSPACERL />
						<TradeFormInputLabel
							allowPointer
							className="inline-flex items-center text-xs"
						>
							<div className="inline-flex">
								Margin Trading {userHasMarginEnabled ? 'Enabled' : 'Disabled'}
							</div>
							{!isDelegateAccount && (
								<div
									className="inline-flex hover:cursor-pointer"
									onClick={goToMarginSettings}
								>
									<Info className="ml-1" size={14} />
								</div>
							)}
						</TradeFormInputLabel>
					</>

					{tradeFormState.showLeverageSlider && userHasMarginEnabled && (
						<>
							<Utility.VERTSPACERS />
							<div className="font-display">
								<SliderButtonComboInput
									currentLeverage={currentLeverage}
									targetTradeQuoteSize={rawQuoteValue}
									totalCurrentPositionSize={totalCurrentPositionSize}
									targetMarketCurrentPositionSize={currentPositionSize}
									sameSide={
										!tradeFormState.currentPosition?.baseAssetAmount ||
										tradeFormState.currentPosition?.baseAssetAmount.eq(ZERO) ||
										(tradeFormState.currentPosition?.baseAssetAmount?.isNeg()
											? 'sell'
											: 'buy') === side
									}
									maxQuoteSize={tradeFormState.maxQuoteAvailable}
									maxLeverage={tradeFormState.maxLeverageAvailable}
									title="Leverage"
									onCurrentSizeChange={handleSliderChange}
									leverageAfterTrade={leverageAfterTrade}
									setMaxLevSelected={setMaxLevSelected}
									disabled={!connected}
								/>
							</div>
						</>
					)}

					{tradeFormState.showReducePositionSlider && userHasMarginEnabled && (
						<>
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-2">
								<TradeFormInputLabel>{'Position Size (%)'}</TradeFormInputLabel>
							</div>
							<Slider
								type="percent"
								disabled={null}
								value={parseFloat(
									currentPositionBaseSize.eqZero()
										? '0'
										: rawBaseValue.toPercentage(currentPositionBaseSize, 4)
								)}
								onDrop={(val) => {
									handleFormChangeValue(
										currentPositionBaseSize.scale(val, 100).printShort(true),
										'base'
									);
								}}
								onMove={(val) => {
									handleFormChangeValue(
										currentPositionBaseSize.scale(val, 100).printShort(true),
										'base'
									);
								}}
								step={1}
								maxButtonTransition={false}
								max={100}
								min={0}
							/>
						</>
					)}

					{connected && showMarginTradingMessage && (
						<OnboardingTooltip
							content={
								<div className="items-center inline-block">
									<Text.BODY2>
										Spot Margin Trading allows you to leverage your deposits as
										collateral to borrow any asset at a variable rate. You can
										enable this later in Settings.{' '}
										<a
											href="https://docs.drift.trade/trading/spot-margin-trading"
											target="_blank"
											rel="noreferrer"
										>
											Learn more
										</a>
									</Text.BODY2>
									<div className="inline-flex items-center mt-2">
										<Button.Secondary
											className="whitespace-nowrap"
											size="MEDIUM"
											highlight
											onClick={goToMarginSettings}
										>
											Enable Spot Margin Trading
										</Button.Secondary>
										<Button.Ghost
											className="ml-1 whitespace-nowrap text-text-secondary"
											size="SMALL"
											onClick={() => setShowMarginTradingMessage(false)}
										>
											Dismiss
										</Button.Ghost>
									</div>
								</div>
							}
							visible
						>
							<SizeSelector
								reduceOnly={reduceOnly}
								baseSizeStringValue={UI_UTILS.roundToStepSizeIfLargeEnough(
									baseSizeStringValue,
									stepSize
								)}
								formattedMarketSymbol={formattedMarketSymbol}
								quoteSizeStringValue={quoteSizeStringValue}
								maxQuoteAvailable={tradeFormState.maxQuoteAvailable}
								maxBaseAvailableString={maxBaseSizeString}
								showMaxButton={tradeFormState.showMaxButton}
								currentPositionBazeSizeString={currentPositionBazeSizeString}
								onBaseSizeChange={onBaseSizeChange}
								handleQuoteSizeChange={handleQuoteSizeChange}
								stepSize={stepSize}
								userHasSelfImposedMax={!!maxMarginRatio && maxMarginRatio !== 0}
								orderType={tradeFormState.orderType}
								isInDepositAndTradeState={false}
							/>
						</OnboardingTooltip>
					)}

					{tradeFormState.showSlippageTolerance && (
						<>
							<Utility.VERTSPACERL />
							<div className="block">
								<SlippageToleranceDisplay />
							</div>
						</>
					)}

					{(tradeFormState.showReduceOnly || tradeFormState.showPostOnly) && (
						<>
							<Utility.VERTSPACERM />
							<div className="flex gap-2">
								{tradeFormState.showReduceOnly && (
									<SwitchInput
										label="Reduce Only"
										checked={reduceOnly && !postOnly}
										onChange={() => toggleReduceOnly(!reduceOnly)}
										tooltip={
											postOnly
												? postOnlyAndReduceOnlyNotAllowedTooltip
												: reduceOnlyTooltip
										}
										disabled={postOnly && tradeFormState.orderType != 'market'}
									/>
								)}
								{tradeFormState.showPostOnly && (
									<SwitchInput
										label="Post"
										checked={postOnly && !reduceOnly}
										onChange={togglePostOnly}
										tooltip={
											reduceOnly
												? postOnlyAndReduceOnlyNotAllowedTooltip
												: postOnlyTooltip
										}
										disabled={reduceOnly}
									/>
								)}
							</div>
						</>
					)}

					{tradeFormState.shouldShowMessage && (
						<>
							<div className="pb-2" />
							<InfoMessage
								type={tradeFormState.messageType}
								message={tradeFormState.message}
								link={tradeFormState.messageLink}
								linkDescription={tradeFormState.messageLinkDescription}
								messageTitle={tradeFormState.messageTitle}
								messageId={tradeFormState.messageId}
							/>

							<div className="pb-2" />
						</>
					)}
				</div>

				<div className={`flex flex-col justify-center mt-12}`}>
					<AdvancedSpotTradeSettings orderType={tradeFormState.orderType} />
					<Utility.VERTSPACERS />
					<div className="flex items-left">
						<SettingsSwitch
							label="Show Confirmation"
							checked={showTradeConfirmation}
							onChange={handleToggleShowConfirmation}
							spacedOut
							includeLineSpace={false}
							className="w-full [&>span]:text-[12px]"
						/>
					</div>

					<Utility.VERTSPACERS />

					{tradeFormState.showPriceEstimateOracleDivergenceWarning &&
						!showTradeConfirmation && (
							<PriceDivergenceWarningMessage
								hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
								setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
							/>
						)}

					<Utility.VERTSPACERM />

					{connected ? (
						<Button.BigSemantic
							positive={side === 'buy'}
							disabled={
								tradeFormState.tradeButtonIsDisabled ||
								(tradeFormState.showPriceEstimateOracleDivergenceWarning &&
									!showTradeConfirmation &&
									!hasAgreedToPriceDivergence) ||
								(isGeoblocked && !reduceOnly) ||
								isSendingTransaction
							}
							onClick={onSubmit}
						>
							{tradeFormState.marketInSettlement ? (
								<Text.H3
									className={`flex items-center justify-center whitespace-normal ${
										currentMarketSymbol.length > 8 ? 'text-lg' : ''
									}`}
								>
									{currentMarketSymbol} IN SETTLEMENT
								</Text.H3>
							) : (
								<Text.H3
									className={`flex items-center justify-center whitespace-normal ${
										currentMarketSymbol.length > 8 ? 'text-lg' : ''
									}`}
								>
									{`${side === 'buy' ? 'BUY' : 'SELL'} ${
										rawBaseValue.gt(ZERO)
											? `${
													leadSide != 'base' ? `~` : ``
											  }${UI_UTILS.roundToStepSizeIfLargeEnough(
													baseSizeStringValue,
													stepSize
											  )}`
											: ''
									} ${currentMarketSymbol}`}
								</Text.H3>
							)}
						</Button.BigSemantic>
					) : (
						<Button.Secondary
							size="LARGE"
							onClick={onSubmit}
							disabled={isGeoblocked}
						>
							{isGeoblocked ? (
								'Unavailable'
							) : (
								<>
									<Wallet
										className="mr-1"
										color="var(--text-default)"
										size={20}
									/>
									<span className="mt-0.5"> Connect Wallet</span>
								</>
							)}
						</Button.Secondary>
					)}
				</div>

				{currentlySelectedMarketInfo.isDriftSpotMarket && (
					<div className="mt-4 text-text-default">
						<PoweredByOpenBookAndPhoenix />
					</div>
				)}
			</div>
		</>
	);
};

const TradeForm_ = (props: {
	marketType: MarketType;
	isPredictionMarket?: boolean;
}) => {
	// const windowHeight = useWindowSizeStore((s) => s.heightString);
	// const windowIsTall = windowHeight === 'tall';

	return (
		<div
			id="trade_form"
			className={`p-3 relative flex flex-col h-full overflow-auto thin-scroll ${'justify-start'}`}
		>
			{props.isPredictionMarket ? (
				<PredictionMarketTradeForm />
			) : matchEnum(props.marketType, MarketType.PERP) ? (
				<PerpTradeForm />
			) : (
				<SpotTradeForm />
			)}
		</div>
	);
};

export const PredictionMarketTradeForm = ({
	isUrlParamTradeNo,
}: {
	isUrlParamTradeNo?: boolean;
}) => {
	const tradeFormState = usePerpTradeFormStateEngine();
	const numberOfSubAccounts = useLazySubAccounts()?.length ?? 0;
	const isMobile = useIsMobileScreenSize();
	const { captureEvent } = usePostHogCapture();
	const authority = useDriftStore((s) => s.wallet.current?.adapter?.publicKey);
	const currentAccount = useTickedAccountData();
	const currentUserPubKey = currentAccount?.pubKey;
	const isMagicEdenWallet = useDriftStore(
		(s) => s.wallet.current?.adapter?.name === MAGIC_EDEN_WALLET_NAME
	);
	const showConnectWalletOptions = useShowWalletConnectOptions();

	// This hook keeps the input values in the drift store in sync
	useTradeFormInputStateEngine();
	useSyncPrePreparedPerpTradeFormOrder();

	const set = useDriftStore((s) => s.set);
	const connected = useWalletIsConnected();
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const selectedMarketSymbol = useDriftStore(
		(s) => s.selectedMarket.current.symbol
	);
	const selectedMarketBaseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol()
	);
	const {
		blockType: shouldShowIsolatedMarketContentBlock,
		preLaunchTickerName,
		preLaunchMarketId,
	} = useShouldShowIsolatedMarketContentBlock();

	const shouldShowIsolatedPoolsContentBlock =
		typeof currentAccount?.client?.getUserAccount()?.poolId === 'number' &&
		currentAccount?.client?.getUserAccount()?.poolId !== MAIN_POOL_ID;

	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);
	const isInDepositAndTradeState = tradeFormState.isInDepositAndTradeState;

	const actions = useDriftActions();

	const pathname = usePathname();

	const setCurrentOrderType = (orderType: string) =>
		set((s) => {
			//@ts-ignore
			s.tradeForm.orderType = orderType;
		});

	const [savedSettings] = useCurrentSettings();

	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);
	const markPrice = useMarkPrice(selectedMarketId);

	const [isSendingTransaction, setIsSendingTransaction] = useState(false);

	const currentPositionSize = NumLib.formatBn.fromQuote(
		tradeFormState.currentPosition?.quoteAssetAmount ?? new BN(0)
	);

	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);

	const currentMarketSymbol = selectedMarketSymbol;
	const formattedMarketSymbol = selectedMarketBaseAssetSymbol;

	const tickSize =
		marketTickSizes[formattedMarketSymbol as keyof typeof marketTickSizes] ??
		0.01;

	const isMarketInReduceOnly = ENUM_UTILS.match(
		selectedMarketInfo?.info?.account.status,
		MarketStatus.REDUCE_ONLY
	);

	const {
		leadSide,
		quoteSizeStringValue,
		baseSizeStringValue,
		reduceOnly,
		stepSize,
		side,
	} = useDriftStore((s) => ({
		quoteSizeStringValue: s.tradeForm.quoteSizeStringValue,
		baseSizeStringValue: s.tradeForm.baseSizeStringValue,
		leadSide: s.tradeForm.leadSide,
		slippageTolerance: s.tradeForm.slippageTolerance,
		reduceOnly: s.tradeForm.reduceOnly,
		stepSize: s.tradeForm.stepSize,
		side: s.tradeForm.side,
	}));

	const bigNumQuoteValue = BigNum.fromPrint(
		quoteSizeStringValue.toString(),
		QUOTE_PRECISION_EXP
	);
	const rawBaseValue = BigNum.fromPrint(
		baseSizeStringValue.toString(),
		BASE_PRECISION_EXP
	);

	const { priceBoxStringValue } = useDriftStore((s) => s.tradeForm);

	const setPriceBoxStringValue = useSetTradeFormPriceBox();
	const setQuoteFormValue = useSetTradeFormQuoteSize();
	const setBaseSizeStringValue = useSetTradeFormBaseSize();

	const setBaseFormValue = (newBaseSize: string) => {
		const rounded = UI_UTILS.roundToStepSizeIfLargeEnough(
			newBaseSize,
			stepSize
		);

		setBaseSizeStringValue(rounded);
	};

	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const outputParams = useMemo(() => {
		const priceBoxStringValueToUse = isSellPredictionMarket
			? (MAX_PREDICTION_PRICE_NUM - +priceBoxStringValue).toString()
			: priceBoxStringValue;

		return {
			...tradeFormStoreState,
			priceBoxStringValue: priceBoxStringValueToUse,
			targetMarketIndex: selectedMarketId?.marketIndex,
			oraclePrice: oraclePrice?.val,
			markPrice: markPrice?.val,
			currentSettings: savedSettings,
			perpMarketAccount: selectedMarketInfo?.info?.account as PerpMarketAccount,
			priceImpact: isSellPredictionMarket
				? invertPriceImpact(tradeFormStoreState.priceImpact)
				: tradeFormStoreState.priceImpact,
		};
	}, [
		tradeFormStoreState,
		selectedMarketId?.marketIndex,
		oraclePrice?.val,
		markPrice?.val,
		savedSettings,
		selectedMarketInfo?.info?.account,
		isSellPredictionMarket,
	]);

	// const prePreparedTx = usePrePreppedTxStore((s) => s.preppedPerpTradeformTxs);

	// only allow limit and market order types for prediction markets
	useEffect(() => {
		if (
			tradeFormState.orderType !== 'limit' &&
			tradeFormState.orderType !== 'market'
		) {
			setCurrentOrderType('market');
		}
	}, [tradeFormState.orderType]);

	async function onSubmit() {
		try {
			if (isInDepositAndTradeState) {
				set((s) => {
					s.tradeForm.isInDepositToTradeFlow = true;
				});
				actions.showModal('showDepositToTradeModal', true);
				return;
			}

			// hardcode 2 seconds diable after submit
			setIsSendingTransaction(true);
			setTimeout(() => {
				setIsSendingTransaction(false);
			}, 3000);

			if (!connected) {
				set((s) => {
					s.wallet.lastRouteBeforeConnect = pathname;
				});
				showConnectWalletOptions(true);
				return;
			}

			set((s) => {
				s.modals.showTradeFormModal = false;
			});
			captureEvent('opening_new_position', {
				market_type: 'prediction',
				trade_base: +outputParams.baseSizeStringValue,
				trade_notional: +outputParams.quoteSizeStringValue,
				market_symbol: selectedMarketSymbol,
			});
			const result = await actions.openPerpTradeFormOrder(
				outputParams,
				'prediction-trade-form'
			); // TODO: add back pre prepared txn if feasible

			if (result && isMagicEdenWallet) {
				await fetch(GET_API_ROUTE('track-bet-magic-eden'), {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						authority: authority?.toString(),
						userAccount: currentUserPubKey.toString(),
						shares: outputParams.baseSizeStringValue,
						oraclePrice: outputParams.oraclePrice.toString(),
						direction: side,
						orderType: tradeFormState.orderType,
						marketIndex: selectedMarketId.marketIndex,
						marketSymbol: selectedMarketSymbol,
					}),
				});
			}
		} finally {
			//
		}
	}

	const setLeadSide = useCallback((side: 'quote' | 'base') => {
		set((s) => {
			s.tradeForm.leadSide = side;
		});
	}, []);

	const setSide = useCallback((side: 'buy' | 'sell') => {
		set((s) => {
			s.tradeForm.side = side;
			s.tradeForm.closingPosition = false;
		});
	}, []);

	useEffect(() => {
		if (isUrlParamTradeNo !== undefined) {
			setSide(isUrlParamTradeNo ? 'sell' : 'buy');
		}
	}, [isUrlParamTradeNo, setSide]);

	/**
	 * Using the same values to render in the field and use as the current value state causes the fields to act as if over-sanitised.
	 * This function handles the value coming back from the tradeforms on change, which will be the string representation of a number, and updates the relevant parts of the state to match.
	 */
	const handleFormChangeValue = (
		value: string,
		fieldName: 'base' | 'quote' | 'percentage' | 'price',
		turnOffClosePosition = true
	) => {
		// dont allow negative values for any fields
		if (value.charAt(0) === '-') {
			value = '0';
		}

		if (value === '') {
			setBaseFormValue('');
			setQuoteFormValue('');
			if (fieldName === 'price') {
				setPriceBoxStringValue('');
			}
			return;
		}

		// don't allow non-number characters
		if (isNaN(parseInt(value.replace('.', '')))) {
			return;
		}

		// prevent quote value of < 1e-6
		if (value.includes('e-') && fieldName === 'quote') {
			value = '0';
		}

		// Don't allow entering a value longer than 10 digits because it makes the UI look strange. A user needing to type more than 10 digits into either of the size fields is unrealistic anyway
		value = value.slice(0, 10);

		if (turnOffClosePosition) {
			set((s) => {
				s.tradeForm.closingPosition = false;
			});
		}

		switch (fieldName) {
			case 'price': {
				const price = BigNum.fromPrint(value, QUOTE_PRECISION_EXP);

				if (leadSide === 'base') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setQuoteFormValue(price.scalarMul(rawBaseValue).toFixed(2));
					}
				}

				if (leadSide === 'quote') {
					if (price.eqZero()) {
						setQuoteFormValue('');
					} else {
						setBaseFormValue(
							bigNumQuoteValue
								.scale(QUOTE_PRECISION, price.val)
								.toTradePrecision()
						);
					}
				}

				setPriceBoxStringValue(value);
				break;
			}
			case 'base': {
				setLeadSide('base');
				setBaseFormValue(value);
				break;
			}
			case 'quote': {
				setLeadSide('quote');
				setQuoteFormValue(value);
				break;
			}
			case 'percentage': {
				const numericalValue = Math.max(parseFloat(value ?? '0'), 0);
				const percentage = Math.min(Math.max(numericalValue, 0), 100);

				// If current leverage is 1x, slider will already have percentage 20%, but that *actually* means that we're not trying to take any quote size ... adjust for this
				const currentLeverage =
					currentPositionSize /
					((tradeFormState.maxQuoteAvailable + currentPositionSize) / 5);

				const currentLeveragePercentage =
					(currentLeverage / tradeFormState.maxLeverageAvailable) * 100;

				// "available percentage" isn't 100% if we already have leverage .. e.g. if we're already at 50% leverage, only 50% of the slider is available ... adjust quote amount for that
				const availablePercentage = 100 - currentLeveragePercentage;
				const newQuoteSize = Math.max(
					((percentage - currentLeveragePercentage) / availablePercentage) *
						tradeFormState.maxQuoteAvailable,
					0
				);
				setLeadSide('quote');
				setQuoteFormValue(newQuoteSize.toFixed(2));
				break;
			}
		}

		return;
	};

	const onDirectionChange = useCallback((side: 'buy' | 'sell') => {
		setSide(side);
	}, []);

	const onOrderTypeChange = useCallback((newValue: string) => {
		setCurrentOrderType(newValue);
	}, []);

	const onBaseSizeChange = useCallback(
		(value: string) => {
			handleFormChangeValue(value, 'base');
		},
		[stepSize]
	);

	const handleQuoteSizeChange = useCallback(
		(value: string) => handleFormChangeValue(value, 'quote'),
		[]
	);

	// slider stays focused when mouse leaves the tradeform while dragging - this fixes by unfocusing it when you re-enter
	const unFocus = () => {
		//@ts-ignore
		if (document.activeElement.role == 'slider') {
			//@ts-ignore
			document.activeElement.blur();
		}
	};

	const submitButton =
		isInDepositAndTradeState || connected ? (
			<Button.Gradient
				size="LARGE"
				disabled={
					tradeFormState.tradeButtonIsDisabled ||
					tradeFormStoreState.bracketOrders?.anyInvalid ||
					(tradeFormState.showPriceEstimateOracleDivergenceWarning &&
						!hasAgreedToPriceDivergence) ||
					(isGeoblocked && !reduceOnly) ||
					isSendingTransaction
				}
				onClick={onSubmit}
			>
				{tradeFormState.marketInSettlement ? (
					<Text.H3
						className={`flex items-center justify-center whitespace-normal`}
					>
						{`${currentMarketSymbol} IN SETTLEMENT`}
					</Text.H3>
				) : isInDepositAndTradeState ? (
					<Text.H3
						className={`flex items-center justify-center whitespace-normal`}
					>
						{`Deposit and Bet ${side === 'buy' ? 'Yes' : 'No'}`}
					</Text.H3>
				) : (
					<Text.H3
						className={`flex items-center justify-center whitespace-normal`}
					>
						Bet {side === 'buy' ? 'Yes' : 'No'}
					</Text.H3>
				)}
			</Button.Gradient>
		) : (
			<Button.Secondary size="LARGE" onClick={onSubmit} disabled={isGeoblocked}>
				{isGeoblocked ? (
					'Unavailable'
				) : (
					<>
						<Wallet className="mr-1" color="var(--text-default)" size={20} />
						<span className="mt-0.5"> Connect Wallet </span>
					</>
				)}
			</Button.Secondary>
		);

	const setMaxLevSelected = useCallback(
		(newSelection: boolean) =>
			set((s) => {
				s.tradeForm.maxLeverageSelected = newSelection && !isMarketInReduceOnly; // we don't want to let contract decide max size if market is in reduce only
			}),
		[isMarketInReduceOnly]
	);

	return (
		<div
			className={twMerge(BLUR_OVERLAY_PARENT_CLASSES, 'h-full', 'relative')}
			onMouseLeave={unFocus}
			{...InvariantChecker.getInvariantCheckerContextWrapperHtmlAttributes(
				'prediction-trade-form'
			)}
		>
			{shouldShowIsolatedMarketContentBlock && (
				<IsolatedMarketContentBlock
					blockType={shouldShowIsolatedMarketContentBlock}
					ticker={preLaunchTickerName}
					marketId={preLaunchMarketId}
					hasMultipleSubaccounts={numberOfSubAccounts > 1}
				/>
			)}

			{shouldShowIsolatedPoolsContentBlock && (
				<IsolatedPoolsContentBlock
					hasMultipleSubaccounts={numberOfSubAccounts > 1}
				/>
			)}

			<div
				className={twMerge(
					'h-full flex flex-col',
					BLUR_OVERLAY_SIBLING_CLASSES,
					shouldShowIsolatedMarketContentBlock &&
						ACTIVE_BLUR_OVERLAY_SIBLING_CLASSES
				)}
			>
				<div className="flex flex-col h-full md:p-6">
					<MarketLimitToggle
						orderType={tradeFormState.orderType}
						onChange={onOrderTypeChange}
					/>

					{isMobile && <MarkPriceDisplay />}

					<Text.BODY2 className={'text-text-label mb-2'}>
						Select An Outcome
					</Text.BODY2>

					<YesNoToggle onChange={onDirectionChange} />

					<PredictionMarketYesAndNoPriceDisplay />

					{tradeFormState.orderType === 'limit' && (
						<LimitPriceBoxLite
							onChange={setPriceBoxStringValue}
							value={priceBoxStringValue}
							tickSize={tickSize}
							className="mt-3 mb-0"
						/>
					)}

					<SizeSelectorLite
						baseStepSize={stepSize}
						quoteStepSize={0.01}
						baseSizeStringValue={baseSizeStringValue}
						quoteSizeStringValue={quoteSizeStringValue}
						maxQuote={tradeFormState.maxQuoteAvailable}
						maxBase={tradeFormState.maxBaseAvailable}
						handleChangeSizeBase={onBaseSizeChange}
						handleChangeSizeQuote={handleQuoteSizeChange}
						handleChangeMaxLeverageSelected={setMaxLevSelected}
						className="mt-3"
						isInDepositAndTradeState={isInDepositAndTradeState}
						defaultSide={leadSide}
						baseSizeInputId={INVARIANT_CHECKER_INPUT_IDS.baseSizeInput}
					/>

					{tradeFormState.orderType === 'market' && (
						<MarkPriceLite
							value={markPrice?.toNum()}
							warn={tradeFormState.showPriceEstimateOracleDivergenceWarning}
							hideSlippageTolerance
							className="mt-0 mb-2"
						/>
					)}

					<div className="flex items-center grow">
						{tradeFormState.shouldShowMessage && (
							<InfoMessage
								type={tradeFormState.messageType}
								message={tradeFormState.message}
								link={tradeFormState.messageLink}
								linkDescription={tradeFormState.messageLinkDescription}
								messageTitle={tradeFormState.messageTitle}
								messageId={tradeFormState.messageId}
							/>
						)}
					</div>

					{tradeFormState.showPriceEstimateOracleDivergenceWarning && (
						<PriceDivergenceWarningMessage
							hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
							setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
						/>
					)}

					<div className="flex flex-col justify-end flex-grow"></div>

					{submitButton}

					<div className="mt-5">
						<AdvancedPerpTradeSettings
							isPredictionMarket
							orderType={tradeFormState.orderType}
							isInDepositAndTradeState={isInDepositAndTradeState}
							isSignedMsgOrderSelected={tradeFormState.isSwiftSelected}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const TradeForm = React.memo(TradeForm_);
