'use client';

import { Gas, Info, Success } from '@drift-labs/icons';
import {
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	convertToNumber,
	MarketType,
	MAX_PREDICTION_PRICE,
	PerpMarketAccount,
	PositionDirection,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	TEN_THOUSAND,
} from '@drift-labs/sdk';
import { COMMON_UI_UTILS, UIOrderType } from '@drift/common';
import { EventEmitter } from 'events';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Text from 'src/components/Text/Text';
import Tooltip from 'src/components/Tooltip/Tooltip';
import ValueDisplay from 'src/components/ValueDisplay';
import useAccountData from 'src/hooks/useAccountData';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { useBooleanLocalStorageState } from 'src/utils/localStorageUtils';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import { ZERO } from '../../constants/constants';
import useInfoForCurrentlySelectedMarket from '../../hooks/useInfoForCurrentlySelectedMarket';
import { useTradeformPriceImpact } from '../../hooks/usePriceImpact';
import { useShowTradeformLiquidityWarnings } from '../../hooks/useShowLiquidityWarnings';
import useUserAccountIsReady from '../../hooks/useUserAccountIsReady';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import MakerRebateTooltip from '../Tooltip/MakerRebateTooltip';
import TradeformLiquidityWarning from '../TradeformLiquidityWarning';
import { AuctionPriceTooltip, SlippageTooltip } from './PriceTooltips';
import EstimatedProfit from './EstimatedProfit';
import {
	useCurrentMarketStepSizeBn,
	useMarketStepSize,
} from 'src/hooks/useMarketStepSize';
import { useFuelBoostsForMarket } from 'src/hooks/fuel/useFuelBoostedMarkets';
import { useWindowHeightIsGreaterThan } from 'src/hooks/useWindowSize';
import { getPerpLiqPrice } from 'src/components/TradeForm/useTradeFormStateEngine';
import { useCollateralValueRequiredForTrade } from 'src/components/TradeForm/useCollateralValueRequiredForTrade';
import Utility from '../Inputs/Utility';
import useTradeFormPreviewOrder, {
	PreviewOrderParams,
} from './useTradeFormPreviewOrder';
import { calculateAveragePrice } from 'src/utils/scaledOrders';
import { useSignedMsgOrdersEnablementStatus } from 'src/hooks/signedMsgOrders/useSignedMsgOrdersEnablementStatus';

const liteRowClassName = 'w-full flex flex-row items-center justify-between';

const AdvancedPerpTradeSettings = (props: {
	orderType: UIOrderType;
	isPredictionMarket?: boolean;
	isInDepositAndTradeState?: boolean;
	isSignedMsgOrderSelected?: boolean;
}) => {
	const isLiteMode = useIsLiteMode();

	const tradeForm = useDriftStore((s) => s.tradeForm);

	const isPostOnly = useDriftStore(
		(s) =>
			s.tradeForm.postOnly &&
			(s.tradeForm.orderType === 'limit' ||
				s.tradeForm.orderType === 'oracleLimit' ||
				s.tradeForm.orderType === 'scaledOrders')
	);

	const displayDecimals =
		useInfoForCurrentlySelectedMarket()?.info?.genericInfo
			?.priceDisplayDecimals;

	const {
		baseSizeStringValue,
		quoteSizeStringValue,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		scaledOrders: { sizeDistribution, orderCount },
	} = useDriftStore((s) => s.tradeForm);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);

	const currentAccount = useAccountData();

	const selectedMarketIndex = useDriftStore(
		(s) => s.selectedMarket.current.market.marketIndex
	);
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const side = tradeForm.side;
	const isFlippingPosition = tradeForm.isFlippingPosition;
	const driftClientIsReady = useDriftClientIsReady();

	const currentPosition =
		currentAccount?.openPerpPositions?.find(
			(pos) => pos.marketIndex === selectedMarketIndex
		) ?? null;

	const quoteSize = Number(quoteSizeStringValue);
	const marketId = selectedMarket.marketId;

	const {
		entryPrice,
		priceImpact,
		priceImpactInputBaseSize,
		showPriceEstimateOracleDivergenceWarning:
			priceImpactOracleDivergenceWarning,
	} = useTradeformPriceImpact();

	const previewOrderParams = useTradeFormPreviewOrder();

	const showOracleDivergenceWarning = previewOrderParams
		? previewOrderParams.isDivergentFromOracle
		: priceImpactOracleDivergenceWarning;

	const stepSize = useMarketStepSize(marketId);

	const { marketBoosters } = useFuelBoostsForMarket(selectedMarket);
	const hasTakerAndMakerBoosts =
		marketBoosters.find((boost) => boost.boosterType === 'taker') &&
		marketBoosters.find((boost) => boost.boosterType === 'maker');

	const getPriceImpactDisplayValue = () => {
		const priceImpactNum = BigNum.from(
			priceImpact ? priceImpact : 0,
			PRICE_PRECISION_EXP
		).toNum();

		const priceImpactPct = priceImpactNum * 100;

		if (priceImpactPct < 0.01) {
			return `<0.01`;
		} else {
			return priceImpactPct.toFixed(2);
		}
	};
	const getOraclePriceForMarket = useGetOraclePriceForMarket();

	const oraclePrice = getOraclePriceForMarket(marketId);

	const quoteSizeNum = Number(quoteSizeStringValue) || 0;
	const priceBoxBigNum = BigNum.fromPrint(
		priceBoxStringValue,
		PRICE_PRECISION_EXP
	);

	const quoteSizeForFeeCalc =
		props.orderType === 'oracleLimit'
			? (oraclePrice?.toNum() + priceBoxBigNum.toNum()) *
			  (Number(baseSizeStringValue) || 0)
			: quoteSizeNum;

	const { tradeFee } = useCalculateTradeFee({
		quoteSize: quoteSizeForFeeCalc,
		marketIndex: selectedMarketIndex,
		marketType: MarketType.PERP,
		isPostOnly: isPostOnly,
	});

	const getEntryPrice = () =>
		BigNum.from(entryPrice ? entryPrice : 0, PRICE_PRECISION_EXP);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [expectedPrice, _setExpectedPrice] = useState(getEntryPrice());
	const [estimatedPriceImpact, setEstimatedPriceImpact] = useState('0%');
	const [marketAccount, setMarketAccount] = useState<PerpMarketAccount>(null);

	const userAccountIsReady = useUserAccountIsReady();

	const currentLeverage = currentAccount?.marginInfo?.leverage ?? 0;

	let currentLiquidationPrice = BigNum.from(
		currentPosition?.liqPrice ?? ZERO,
		PRICE_PRECISION_EXP
	).toNum();
	const driftClient = useDriftStore((s) => s.driftClient.client);

	currentLiquidationPrice =
		currentLiquidationPrice < 0 ? 0 : currentLiquidationPrice;

	useEffect(() => {
		if (driftClientIsReady) {
			setMarketAccount(driftClient?.getPerpMarketAccount(selectedMarketIndex));
		}
	}, [driftClientIsReady, selectedMarketIndex]);

	useEffect(() => {
		setEstimatedPriceImpact(`${getPriceImpactDisplayValue()}%`);
	}, [priceImpact?.toString()]);

	const signedMsgOrdersEnablementStatus = useSignedMsgOrdersEnablementStatus();

	const liqPriceAfter = getPerpLiqPrice(
		entryPrice,
		props.orderType as UIOrderType,
		marketAccount,
		baseSizeStringValue,
		priceBoxStringValue,
		side === 'buy',
		currentAccount?.client,
		undefined,
		displayDecimals
	);

	const previousBaseValue = BigNum.from(
		currentPosition?.baseSize,
		AMM_RESERVE_PRECISION_EXP
	);

	const baseSize = BigNum.fromPrint(
		baseSizeStringValue ? baseSizeStringValue : '0',
		AMM_RESERVE_PRECISION_EXP
	);

	const afterBaseValue = previousBaseValue.add(
		baseSize.mul(BigNum.from(side === 'buy' ? new BN(1) : new BN(-1)))
	);

	const baseValueForCalcProfit = isFlippingPosition
		? baseSize.abs().gt(previousBaseValue.abs())
			? baseSize.abs().sub(previousBaseValue.abs())
			: BigNum.zero(BASE_PRECISION_EXP)
		: baseSize;

	let leverageRatioAfterTrade;
	try {
		leverageRatioAfterTrade =
			!currentAccount ||
			!userAccountIsReady ||
			currentAccount.client?.getTotalCollateral() === ZERO
				? 0
				: convertToNumber(
						currentAccount.client?.accountLeverageRatioAfterTrade(
							selectedMarketIndex,
							MarketType.PERP,
							NumLib.formatNum.toQuoteBN(quoteSize),
							side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
						) ?? ZERO,
						TEN_THOUSAND
				  );
	} catch (e) {
		console.error(e);
		leverageRatioAfterTrade = 0;
	}

	// this component is used on lite mode, but we show the SignedMsgOrder free gas fees display in a different part of the form for lite mode, so can't show it twice
	const showSignedMsgOrderFreeGasFees =
		props.isSignedMsgOrderSelected &&
		!isLiteMode &&
		signedMsgOrdersEnablementStatus.status === 'enabled';

	const previousDirection = previousBaseValue.lt(ZERO)
		? 'SHORT'
		: previousBaseValue.eq(ZERO)
		? ''
		: 'LONG';

	const afterDirection = afterBaseValue.lt(ZERO)
		? 'SHORT'
		: afterBaseValue.eq(ZERO)
		? ''
		: 'LONG';

	const currentStepSize = useCurrentMarketStepSizeBn();
	const currentStepSizeBigNum = BigNum.from(
		currentStepSize,
		BASE_PRECISION_EXP
	);
	const scaledOrdersAveragePrice: BN | null = useMemo(() => {
		try {
			return props.orderType === 'scaledOrders'
				? calculateAveragePrice(
						sizeDistribution,
						baseSize,
						currentStepSizeBigNum,
						orderCount,
						priceBoxBigNum.val,
						BigNum.fromPrint(secondaryPriceBoxStringValue, PRICE_PRECISION_EXP)
							.val
				  )
				: null;
		} catch {
			return null;
		}
	}, [
		sizeDistribution,
		baseSize.val,
		orderCount,
		stepSize,
		priceBoxBigNum,
		secondaryPriceBoxStringValue,
	]);

	const [hasShownJitTooltip, setHasShownJitTooltip] =
		useBooleanLocalStorageState('HasShownJitExplanationTooltip', false);

	const jitTooltipEmitter = useRef(new EventEmitter());

	// Show the JIT tooltip if the user has interacted with the trade form and they haven't seen it before
	useEffect(() => {
		if (priceImpact && priceImpact.gt(ZERO) && !hasShownJitTooltip) {
			jitTooltipEmitter.current.emit('event');
			setHasShownJitTooltip(true);
		}
	}, [hasShownJitTooltip, priceImpact?.toString()]);

	const showLiquidityWarningState = useShowTradeformLiquidityWarnings();

	// check if price impact is up to date, allow 1% leeway for small fluctuations
	const priceImpactIsCurrent = BigNum.fromPrint(
		baseSizeStringValue,
		BASE_PRECISION_EXP
	)
		.sub(BigNum.from(priceImpactInputBaseSize, BASE_PRECISION_EXP))
		.abs()
		.lte(
			BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP).scale(1, 100)
		);

	const limitExitSize = BigNum.fromPrint(
		baseSizeStringValue,
		BASE_PRECISION_EXP
	);
	const currentPositionBaseSize = currentPosition
		? BigNum.from(currentPosition.baseSize, BASE_PRECISION_EXP).abs()
		: BigNum.zero(BASE_PRECISION_EXP);

	const showEstLiqPrice = !props.isInDepositAndTradeState;

	const orderIsIncreasingPosition =
		currentPosition &&
		((currentPosition.direction === 'short' && side === 'sell') ||
			(currentPosition.direction === 'long' && side === 'buy'));

	if (props.isPredictionMarket) {
		const previousPredictionDirection =
			previousDirection === 'SHORT'
				? 'No'
				: previousDirection === 'LONG'
				? 'Yes'
				: '';

		const afterPredictionDirection =
			afterDirection === 'SHORT'
				? 'No'
				: afterDirection === 'LONG'
				? 'Yes'
				: '';

		let entryPriceToUse =
			props.orderType === 'limit'
				? BigNum.fromPrint(priceBoxStringValue, PRICE_PRECISION_EXP).val
				: entryPrice;

		entryPriceToUse = isSellPredictionMarket
			? MAX_PREDICTION_PRICE.sub(entryPriceToUse)
			: entryPriceToUse;

		return (
			<div className="flex flex-col w-full space-y-2 rounded-md advanced-trade-details">
				<div className={liteRowClassName}>
					<div className="flex items-center space-x-1 text-text-label">
						<Text.BODY3 className="">Potential Profits</Text.BODY3>
						<Tooltip
							content={
								<div className="flex flex-col space-y-2">
									<Text.BODY3>
										If your prediction is correct, the market will be resolved
										at a $1 value. Your profit per share will be the difference
										between your entry price and $1.
									</Text.BODY3>
								</div>
							}
						/>
					</div>
					{!entryPriceToUse.eq(ZERO) && !baseValueForCalcProfit.eqZero() ? (
						<Text.BODY3 className="text-text-default">
							{BigNum.from(
								UI_UTILS.getPredictionPositionPotentialPnl(
									baseValueForCalcProfit.val,
									entryPriceToUse,
									side === 'buy'
										? PositionDirection.LONG
										: PositionDirection.SHORT
								),
								QUOTE_PRECISION_EXP
							).toNotional()}
						</Text.BODY3>
					) : baseValueForCalcProfit.eqZero() ? (
						<Text.BODY3 className="text-text-default">-</Text.BODY3>
					) : (
						<SkeletonValuePlaceholder className="h-[14px] w-[50px]" loading />
					)}
				</div>

				<ValueDisplay.ValueChange
					label="Position"
					previousValue={previousBaseValue.abs().toNum()}
					afterValue={afterBaseValue.abs().toNum()}
					previousValuePrint={`${UI_UTILS.roundToStepSizeIfLargeEnough(
						previousBaseValue.abs().toNum().toString(),
						stepSize
					)} ${previousPredictionDirection}`}
					afterValuePrint={`${UI_UTILS.roundToStepSizeIfLargeEnough(
						afterBaseValue.abs().toNum().toString(),
						stepSize
					)} ${afterPredictionDirection}`}
					forceWhite
				/>

				{showEstLiqPrice && (
					<div className={liteRowClassName}>
						<div className="flex items-center space-x-1 text-text-label">
							<Text.BODY3 className="text-text-label">
								Est. Liquidation Price
							</Text.BODY3>
							<Tooltip
								content={
									<>
										{`The margin requirement for a prediction market trade stays constant so you can't be liquidated from a price movement in the market.`}
										<br />
										<br />
										{`However you can be liquidated if the value of your non-USDC collateral
									deposits falls below the margin requirement.`}
									</>
								}
							/>
						</div>

						<Text.BODY3 className="text-text-default">
							{baseSizeStringValue && baseSizeStringValue !== '0'
								? liqPriceAfter !== 0
									? COMMON_UI_UTILS.trimTrailingZeros(
											UI_UTILS.toNotional(liqPriceAfter, 5)
									  )
									: 'None'
								: ''}
						</Text.BODY3>
					</div>
				)}

				{hasTakerAndMakerBoosts && (
					<div className={liteRowClassName}>
						<Text.BODY3 className="text-text-label">FUEL Earn</Text.BODY3>

						<Text.BODY3 className="flex items-center gap-[2px] text-text-default">
							<img
								src="/assets/images/fuel/fuel-droplet.svg"
								alt="Fuel Boosted"
								className="h-[16px]"
							/>
							<span>
								{
									NumLib.millify(
										quoteSize *
											(marketBoosters.find(
												(boost) => boost.boosterType === 'taker'
											).boosterAmount ?? 0)
									).displayString
								}{' '}
								FUEL
							</span>
						</Text.BODY3>
					</div>
				)}
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col w-full space-y-2 rounded-md advanced-trade-details">
				{props.orderType === 'limit' &&
					currentPosition &&
					limitExitSize.lte(currentPositionBaseSize) &&
					limitExitSize.gtZero() &&
					!orderIsIncreasingPosition && (
						<EstimatedProfit
							marketId={marketId}
							currentPositionSize={currentPositionBaseSize}
							currentPositionEntryPrice={BigNum.from(
								currentPosition.entryPrice,
								PRICE_PRECISION_EXP
							)}
							currentPositionDirection={currentPosition.direction}
							side={side}
							limitExitSize={limitExitSize}
							limitExitPrice={BigNum.fromPrint(
								priceBoxStringValue,
								PRICE_PRECISION_EXP
							)}
						></EstimatedProfit>
					)}

				{props.orderType === 'market' &&
					showLiquidityWarningState.state !== 'hide' && (
						<TradeformLiquidityWarning
							warningState={showLiquidityWarningState}
						/>
					)}

				{props.orderType === 'market' ? (
					<>
						<AdvancedTradeSettingsRenderer
							baseSizeStringValue={baseSizeStringValue}
							jitTooltipEmitter={jitTooltipEmitter.current}
							previewOrderParams={previewOrderParams}
							expectedPrice={
								baseSizeStringValue &&
								Number(baseSizeStringValue) > 0 &&
								expectedPrice &&
								expectedPrice.gt(ZERO) &&
								priceImpactIsCurrent
									? expectedPrice.toFixed(displayDecimals)
									: undefined
							}
							priceImpactIsCurrent={priceImpactIsCurrent}
							priceImpact={priceImpact ? priceImpact.toNumber() : undefined}
							estimatedPriceImpact={
								priceImpact &&
								Number(baseSizeStringValue) > 0 &&
								baseSizeStringValue &&
								priceImpactIsCurrent
									? priceImpact.gt(ZERO)
										? estimatedPriceImpact
										: undefined
									: undefined
							}
							liqPriceAfter={liqPriceAfter}
							currentLiquidationPrice={currentLiquidationPrice}
							selectedMarketIndex={selectedMarketIndex}
							userAccountIsReady={userAccountIsReady}
							currentLeverage={currentLeverage}
							leverageRatioAfterTrade={leverageRatioAfterTrade}
							overrideNoValueChange={!baseSize || baseSize.eqZero()}
							showPriceEstimateOracleDivergenceWarning={
								showOracleDivergenceWarning
							}
							isInDepositAndTradeState={props.isInDepositAndTradeState}
							side={side}
						/>
					</>
				) : (
					<></>
				)}

				{scaledOrdersAveragePrice && (
					<ValueDisplay.Default
						label={<Text.BODY3>Average price</Text.BODY3>}
						value={
							<div>
								<Text.BODY3>
									$
									{NumLib.formatNum.toDisplayPrice(
										NumLib.formatBn.toRawNum(
											scaledOrdersAveragePrice,
											PRICE_PRECISION
										)
									)}
								</Text.BODY3>
							</div>
						}
					/>
				)}

				{/* Liquidation limit price */}
				{props.orderType === 'limit' &&
					(isLiteMode ? (
						<div className={liteRowClassName}>
							<Text.BODY2 className="text-text-label">
								Est. Liquidation Price
							</Text.BODY2>
							<Text.BODY2 className="text-text-default">
								{baseSizeStringValue && baseSizeStringValue !== '0'
									? liqPriceAfter !== 0
										? COMMON_UI_UTILS.trimTrailingZeros(
												UI_UTILS.toNotional(liqPriceAfter, 5)
										  )
										: 'None'
									: ''}
							</Text.BODY2>
						</div>
					) : (
						<ValueDisplay.ValueChange
							label="Est. Liquidation Price"
							previousValue={currentLiquidationPrice}
							afterValue={liqPriceAfter}
							previousValuePrint={
								currentLiquidationPrice !== 0
									? currentLiquidationPrice.toLocaleString('en-US', {
											maximumSignificantDigits: 5,
									  })
									: 'None'
							}
							afterValuePrint={
								liqPriceAfter !== 0
									? COMMON_UI_UTILS.trimTrailingZeros(
											UI_UTILS.toNotional(liqPriceAfter, 5)
									  )
									: 'None'
							}
							forceWhite
							nullOverride={
								!userAccountIsReady ||
								isNaN(selectedMarketIndex) ||
								(!currentLiquidationPrice && !liqPriceAfter)
							}
							customOverride={
								<SkeletonValuePlaceholder
									className="h-[14px] w-[50px]"
									loading={false}
								/>
							}
							overrideNoValueChange={!baseSize || baseSize.eqZero()}
						/>
					))}

				{!isLiteMode && (
					<ValueDisplay.ValueChange
						label="Position"
						previousValue={previousBaseValue.abs().toNum()}
						afterValue={afterBaseValue.abs().toNum()}
						previousValuePrint={`${previousBaseValue
							.abs()
							.toNum()
							.toLocaleString('en-US', {
								maximumSignificantDigits: 5,
							})} ${previousDirection}`}
						afterValuePrint={`${UI_UTILS.roundToStepSizeIfLargeEnough(
							afterBaseValue.abs().toNum().toLocaleString('en-US', {
								maximumSignificantDigits: 5,
							}),
							stepSize
						)} ${afterDirection}`}
						forceWhite
					/>
				)}

				{isPostOnly && (
					<Text.BODY3 className="flex items-end justify-between w-full">
						<div
							className="flex items-center text-text-secondary"
							title="Entry price slippage from mark price."
						>
							<Text.BODY3>Maker Rebate</Text.BODY3>
							<MakerRebateTooltip />
						</div>
						<div className="font-numeral text-text-default">
							~
							<span className="ml-0.5 text-positive-green">
								${parseFloat(tradeFee.toFixed(4))}
							</span>
						</div>
					</Text.BODY3>
				)}

				{!isPostOnly &&
					(props.orderType === 'market' ||
						props.orderType === 'limit' ||
						props.orderType === 'oracleLimit' ||
						props.orderType === 'scaledOrders') && (
						<ValueDisplay.Default
							label={<Text.BODY3>Fees</Text.BODY3>}
							value={
								tradeFee.toFixed(2) === '0.00' ? (
									<Text.BODY3>0</Text.BODY3>
								) : (
									<div>
										<Text.BODY3>${tradeFee.toFixed(2)}</Text.BODY3>
									</div>
								)
							}
						/>
					)}

				{showSignedMsgOrderFreeGasFees && (
					<ValueDisplay.Default
						label={
							<Text.BODY3 className="flex items-center gap-1 text-text-label">
								<Gas /> Gas fees
							</Text.BODY3>
						}
						value={
							<div className="flex items-center gap-1 text-positive-green text-xs">
								<Success /> Free
							</div>
						}
					/>
				)}
			</div>
		</>
	);
};

/**
 * Created this component whose only inputs are the "actual" numbers to display .. did this because the parent component re-renders a lot due to values changing in the store, without the human-readable values on the screen necessarily changing
 * @param param0
 * @returns
 * *
 */
const AdvancedTradeSettingsRendererComponent = ({
	jitTooltipEmitter: _jitTooltipEmitter,
	baseSizeStringValue,
	previewOrderParams,
	expectedPrice: _expectedPrice,
	priceImpactIsCurrent,
	estimatedPriceImpact: _estimatedPriceImpact,
	priceImpact: _priceImpact,
	currentLiquidationPrice,
	liqPriceAfter,
	selectedMarketIndex,
	userAccountIsReady,
	currentLeverage,
	leverageRatioAfterTrade,
	overrideNoValueChange,
	showPriceEstimateOracleDivergenceWarning:
		_showPriceEstimateOracleDivergenceWarning,
	isInDepositAndTradeState,
	side,
}: {
	jitTooltipEmitter: EventEmitter;
	baseSizeStringValue: string;
	previewOrderParams?: PreviewOrderParams;
	expectedPrice?: string;
	priceImpactIsCurrent?: boolean;
	estimatedPriceImpact?: string;
	priceImpact?: number;
	currentLiquidationPrice: number;
	liqPriceAfter: number;
	selectedMarketIndex: number;
	userAccountIsReady: boolean;
	currentLeverage: number;
	leverageRatioAfterTrade: number;
	overrideNoValueChange: boolean;
	showPriceEstimateOracleDivergenceWarning: boolean;
	isInDepositAndTradeState: boolean;
	side: 'buy' | 'sell';
}) => {
	const isLiteMode = useIsLiteMode();
	const isMobile = useIsMobileScreenSize();
	const screenIsTall = useWindowHeightIsGreaterThan('medium');
	const tradeFormSlippageTolerance = useDriftStore(
		(s) => s.tradeForm.slippageTolerance
	);

	const auctionStartElement = (
		<ValueDisplay.Default
			label={
				<span>
					Auction Start Price
					<Info size={14} className="relative top-[3px] left-1" />
				</span>
			}
			value={
				!overrideNoValueChange && previewOrderParams && priceImpactIsCurrent ? (
					<>
						{COMMON_UI_UTILS.trimTrailingZeros(
							UI_UTILS.toNotional(
								BigNum.from(
									previewOrderParams?.auctionStartPrice ?? ZERO,
									PRICE_PRECISION_EXP
								).toNum(),
								5
							),
							2
						)}
					</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[3px]'}
		/>
	);

	const auctionEndElement = (
		<ValueDisplay.Default
			label={
				<div className="flex items-center">
					<span>
						{side === 'buy' ? 'Max' : 'Min'} Price
						<Info size={14} className="relative top-[3px] left-1" />
					</span>
					{previewOrderParams?.constrainedBySlippage && baseSizeStringValue && (
						<div
							className={`text-warn-yellow ml-2 px-1 py-0.5 space-x-2 border rounded text-xs`}
							style={{
								borderColor: 'var(--yellow-80)',
							}}
						>
							Low Slippage
						</div>
					)}
				</div>
			}
			value={
				!overrideNoValueChange && previewOrderParams && priceImpactIsCurrent ? (
					<>
						{COMMON_UI_UTILS.trimTrailingZeros(
							UI_UTILS.toNotional(
								BigNum.from(
									previewOrderParams?.auctionEndPrice ?? ZERO,
									PRICE_PRECISION_EXP
								).toNum(),
								5
							),
							2
						)}
					</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[3px]'}
		/>
	);

	const dynamicSlippageElement = (
		<ValueDisplay.Default
			label={
				<span>
					Dynamic Slippage
					<Info size={14} className="relative top-[3px] left-1" />
				</span>
			}
			value={
				!overrideNoValueChange &&
				previewOrderParams?.slippage &&
				priceImpactIsCurrent &&
				tradeFormSlippageTolerance === 'dynamic' ? (
					<>{parseFloat(previewOrderParams?.slippage?.toFixed(2))}%</>
				) : (
					<SkeletonValuePlaceholder
						className="h-[14px] w-[50px]"
						loading={Number(baseSizeStringValue) > 0}
					/>
				)
			}
			rightPadding={'pt-[3px]'}
		/>
	);

	const collateralValueForTrade = useCollateralValueRequiredForTrade();

	const showEstLiqPrice = !isInDepositAndTradeState;

	return isLiteMode ? (
		<>
			{isInDepositAndTradeState && (
				<div className={liteRowClassName}>
					<Text.BODY2 className="text-text-label">Est. Margin</Text.BODY2>
					<Text.BODY2 className="text-text-default">
						{collateralValueForTrade.toNotional()}
					</Text.BODY2>
				</div>
			)}

			{showEstLiqPrice && (
				<div className={liteRowClassName}>
					<Text.BODY2 className="text-text-label">
						Est. Liquidation Price
					</Text.BODY2>
					<Text.BODY2 className="text-text-default">
						{baseSizeStringValue && baseSizeStringValue !== '0'
							? liqPriceAfter !== 0
								? COMMON_UI_UTILS.trimTrailingZeros(
										UI_UTILS.toNotional(liqPriceAfter, 5),
										2
								  )
								: 'None'
							: ''}
					</Text.BODY2>
				</div>
			)}
		</>
	) : (
		<>
			{tradeFormSlippageTolerance === 'dynamic' ? (
				<>
					{isMobile ? (
						dynamicSlippageElement
					) : (
						<SlippageTooltip
							childElement={dynamicSlippageElement}
							placement="right"
							showHighSlippageWarning={previewOrderParams?.slippage >= 2}
						/>
					)}
				</>
			) : (
				<>
					{isMobile ? (
						<>{auctionStartElement}</>
					) : (
						<AuctionPriceTooltip
							placement="right"
							type="start"
							price={previewOrderParams?.auctionStartPrice ?? ZERO}
							isOracleOrder={previewOrderParams?.isOracleOrder}
							showConstrainedBySlippageWarning={false}
							childElement={auctionStartElement}
						/>
					)}

					{isMobile ? (
						<>{auctionEndElement}</>
					) : (
						<AuctionPriceTooltip
							placement="right"
							type="end"
							price={previewOrderParams?.auctionEndPrice ?? ZERO}
							isOracleOrder={previewOrderParams?.isOracleOrder}
							showConstrainedBySlippageWarning={
								previewOrderParams?.constrainedBySlippage
							}
							childElement={auctionEndElement}
						/>
					)}
				</>
			)}

			<Utility.VERTSPACERXXS />
			<Utility.VERTDIVIDER />
			<Utility.VERTSPACERXS />

			{/* 
			<ValueDisplay.Default
				label="Est. Price Impact"
				value={
					priceImpact !== undefined &&
					!overrideNoValueChange &&
					priceImpactIsCurrent ? (
						priceImpact > 0 ? (
							<>{estimatedPriceImpact}</>
						) : (
							<>{'0%'}</>
						)
					) : (
						<SkeletonValuePlaceholder
							className="h-[14px] w-[50px]"
							loading={!overrideNoValueChange}
						/>
					)
				}
			/> */}

			{showEstLiqPrice && (
				<ValueDisplay.ValueChange
					label="Est. Liquidation Price"
					previousValue={currentLiquidationPrice}
					afterValue={liqPriceAfter}
					previousValuePrint={
						currentLiquidationPrice !== 0
							? currentLiquidationPrice.toLocaleString('en-US', {
									maximumSignificantDigits: 5,
							  })
							: 'None'
					}
					afterValuePrint={
						liqPriceAfter !== 0
							? COMMON_UI_UTILS.trimTrailingZeros(
									UI_UTILS.toNotional(liqPriceAfter, 5)
							  )
							: 'None'
					}
					forceWhite
					nullOverride={
						!userAccountIsReady ||
						isNaN(selectedMarketIndex) ||
						(!currentLiquidationPrice && !liqPriceAfter)
					}
					customOverride={
						<SkeletonValuePlaceholder
							className="h-[14px] w-[50px]"
							loading={false}
						/>
					}
					overrideNoValueChange={overrideNoValueChange}
				/>
			)}

			{screenIsTall && (
				<ValueDisplay.ValueChange
					label="Acct. Leverage"
					rightSymbol="x"
					previousValue={currentLeverage}
					afterValue={leverageRatioAfterTrade}
					previousValuePrint={currentLeverage.toLocaleString('en-US', {
						maximumSignificantDigits: 3,
					})}
					afterValuePrint={leverageRatioAfterTrade.toLocaleString('en-US', {
						maximumSignificantDigits: 3,
					})}
					forceWhite
					nullOverride={
						(!userAccountIsReady ||
							(!currentLeverage && !leverageRatioAfterTrade) ||
							isNaN(selectedMarketIndex)) &&
						true
					}
					customOverride={
						<SkeletonValuePlaceholder
							className="h-[14px] w-[50px]"
							loading={false}
						/>
					}
					overrideNoValueChange={overrideNoValueChange}
				/>
			)}
		</>
	);
};

const AdvancedTradeSettingsRenderer = React.memo(
	AdvancedTradeSettingsRendererComponent
);

export default React.memo(AdvancedPerpTradeSettings);
